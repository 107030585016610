import { IconInfoCircle } from '@tabler/icons-react';
import {
  EmployeeDto,
  EmployeePaymentMethodStatus,
  OnboardingPeriodDto,
} from '@zorro/clients';
import { useMonolithQuery } from '@zorro/shared/utils';
import {
  Box,
  Group,
  ISelectProps,
  Icon,
  Select,
  Stack,
  Title,
  Tooltip,
} from '@zorro/zorro-ui-design';

import { useMonolithMutation } from '../../hooks';

type ManagePaymentMethodStatusProps = {
  employeeId: EmployeeDto['id'];
  onboardingPeriodId: OnboardingPeriodDto['id'];
};

const ManagePaymentMethodStatus = ({
  employeeId,
  onboardingPeriodId,
}: ManagePaymentMethodStatusProps) => {
  const { data, isLoading } = useMonolithQuery({
    method: 'paymentsControllerGetPaymentMethod',
    params: [employeeId, onboardingPeriodId],
  });

  const {
    mutate: updatePaymentMethodStatus,
    isPending: isUpdatingPaymentMethodStatus,
  } = useMonolithMutation({
    method: 'paymentsControllerUpdatePaymentMethod',
    shouldInvalidateQueries: true,
  });

  if (isLoading || !data) {
    return null;
  }

  const { status } = data;

  const handlePaymentMethodStatusChange: ISelectProps['onChange'] = async (
    value
  ) => {
    if (!value) {
      return;
    }

    await updatePaymentMethodStatus([
      employeeId,
      {
        onboardingPeriodId,
        status: value as EmployeePaymentMethodStatus,
      },
    ]);
  };

  return (
    <Stack>
      <Box>
        <Tooltip
          inline
          label="Changing the status does not automatically affect the payment card/account in ECHO"
        >
          <Group gap="0.250rem" display="inline-flex">
            <Title order={3} size="sm">
              Change status
            </Title>
            <Icon icon={IconInfoCircle} size="1rem" />
          </Group>
        </Tooltip>
      </Box>
      <Select
        disabled={isUpdatingPaymentMethodStatus}
        value={status}
        data={[
          { value: 'EMPTY', label: 'No payment method' },
          { value: 'VALID', label: 'Valid' },
          { value: 'INVALID', label: 'Invalid (in-handling)' },
        ]}
        onChange={handlePaymentMethodStatusChange}
        maw="20rem"
      />
    </Stack>
  );
};

export { ManagePaymentMethodStatus };
