import { AllAgenciesDto } from '@zorro/clients';

export const getEmployerAgencyNames = (
  zorroPartnerId: string | null | undefined,
  producerId: string | null | undefined,
  enrollmentTeamIds: string[],
  allAgencies: AllAgenciesDto
): {
  myEnrollmentTeams: string[];
  myZorroPartner?: string;
  myProducer?: string;
} => {
  const myZorroPartner = allAgencies.zorroPartners.find(
    (zorroPartner) => zorroPartner.id === zorroPartnerId
  )?.name;
  const myProducer = [
    ...allAgencies.producers,
    ...allAgencies.zorroPartners,
  ].find((producer) => producer.id === producerId)?.name;
  const myEnrollmentTeams =
    enrollmentTeamIds.length > 0
      ? enrollmentTeamIds.flatMap(
          (agentId) =>
            [...allAgencies.enrollmentTeams, ...allAgencies.zorroPartners].find(
              (agent) => agent.id === agentId
            )?.name ?? []
        )
      : [];

  return {
    myEnrollmentTeams,
    myZorroPartner,
    myProducer,
  };
};
