import { IconArrowsShuffle, IconChevronDown } from '@tabler/icons-react';
import { MURRIETA_ROUTES, useRouter } from '@zorro/shared/utils';
import { Box, Icon, Menu, Space, Text } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

export type MenuItem = {
  text: string;
  icon?: ReactNode;
  onClick: () => void;
};

interface EmployerHeaderTopBarMenuItemProps {
  item: MenuItem;
}

const EmployerHeaderTopBarMenuItem = ({
  item,
}: EmployerHeaderTopBarMenuItemProps) => {
  return (
    <Menu.Item leftSection={item.icon} onClick={item.onClick}>
      {item.text}
    </Menu.Item>
  );
};

type Props = {
  shouldShowMenu?: boolean;
  isProspect: boolean;
  employerName?: string;
  menuItems?: MenuItem[];
};

export const EmployerHeaderMenu = ({
  menuItems,
  employerName,
  isProspect,
  shouldShowMenu = true,
}: Props) => {
  const { router } = useRouter();

  const defaultMenuItems = [
    {
      text: isProspect ? 'Change prospect' : 'Change employer',
      onClick: async () => {
        await router.push(
          isProspect ? MURRIETA_ROUTES.PROSPECTS : MURRIETA_ROUTES.EMPLOYERS
        );
      },
      icon: <Icon icon={IconArrowsShuffle} size={18} />,
    },
  ];

  const headerMenuItems = menuItems || defaultMenuItems;

  return (
    <Menu
      width={256}
      position="bottom-start"
      styles={() => ({
        arrow: {
          display: 'none',
        },
      })}
    >
      <Menu.Target>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {employerName && <Text size="xl">{employerName}</Text>}
          <Space w={12} />
          {shouldShowMenu && <IconChevronDown />}
        </Box>
      </Menu.Target>
      {shouldShowMenu && (
        <Menu.Dropdown>
          {headerMenuItems.map((item) => (
            <EmployerHeaderTopBarMenuItem key={item.text} item={item} />
          ))}
        </Menu.Dropdown>
      )}
    </Menu>
  );
};
