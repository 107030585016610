import { IconAlertTriangle, IconTrash } from '@tabler/icons-react';
import {
  EMPLOYEE_DYNAMIC_ROUTES,
  getFullName,
  useDynamicRouter,
  useMonolithQuery,
} from '@zorro/shared/utils';
import { Drawer, Flex, Icon, Modal, Text, brand } from '@zorro/zorro-ui-design';

import {
  AddEnrollmentRecordForm,
  DeleteEnrollmentRecord,
  EditEmployeeRoleForm,
  EditEnrollmentsRecord,
  EmployeeEmailForm,
  EmployeeLeaveOfAbsenceForm,
  TerminateEmployeeForm,
  UpdateEligibilityForm,
} from '../EmployeeActions';
import { DeleteEmployee } from '../EmployeeActions/AccountManagement/DeleteEmployee';
import { ModalTitle } from '../SimpleActionModal';
import { EditIdFromEmployerForm } from './EmployeePageSidebar/EditIdFromEmployerForm';
import { getEmployeePageRole } from './EmployeePageUtils';

export enum EmployeePageAction {
  EDIT_COMPANY_EMAIL = 'EDIT_COMPANY_EMAIL',
  DELETE_EMPLOYEE = 'DELETE_EMPLOYEE',
  TERMINATE_EMPLOYEE = 'TERMINATE_EMPLOYEE',
  SET_LEAVE_OF_ABSENCE = 'SET_LEAVE_OF_ABSENCE',
  UPDATE_ELIGIBILITY = 'UPDATE_ELIGIBILITY',
  EDIT_EMPLOYEE_ROLE = 'EDIT_EMPLOYEE_ROLE',
  EDIT_ID_FROM_EMPLOYER = 'EDIT_ID_FROM_EMPLOYER',
  ADD_ENROLLMENT = 'ADD_ENROLLMENT',
  EDIT_ENROLLMENT = 'EDIT_ENROLLMENT',
  DELETE_ENROLLMENT = 'DELETE_ENROLLMENT',
}

type Props = {
  employerId: string;
  employeeId: string;
  onboardingPeriodId?: string;
  action?: EmployeePageAction;
  setAction: (action?: EmployeePageAction) => void;
};

export const EmployeePageActions = ({
  employerId,
  employeeId,
  onboardingPeriodId,
  action,
  setAction,
}: Props) => {
  const { navigateToRoute } = useDynamicRouter(EMPLOYEE_DYNAMIC_ROUTES);
  const { data: employee, isLoading: isLoadingEmployee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });
  const { data: userRoles, isLoading: isLoadingRoles } = useMonolithQuery({
    method: 'usersControllerGetUserRoles',
    params: employee && [employerId, employee.userId],
  });

  if (!employee || !userRoles || isLoadingEmployee || isLoadingRoles) {
    return null;
  }

  const handleCloseAction = () => setAction(undefined);
  const role = getEmployeePageRole(userRoles);

  return (
    <>
      <Drawer
        opened={action === EmployeePageAction.EDIT_COMPANY_EMAIL}
        onClose={handleCloseAction}
        title="Edit company email"
      >
        <EmployeeEmailForm
          onSuccess={handleCloseAction}
          onCancel={handleCloseAction}
          employeeId={employeeId}
        />
      </Drawer>
      <Modal
        opened={action === EmployeePageAction.DELETE_EMPLOYEE}
        onClose={handleCloseAction}
        size="lg"
        title={
          <Flex align="center">
            <Icon
              icon={IconAlertTriangle}
              color={brand.zorroFire700}
              size={32}
            />
            <Text size="xl" ml="sm" fw={600}>
              Delete employee?
            </Text>
          </Flex>
        }
      >
        <DeleteEmployee
          onCancel={handleCloseAction}
          employerId={employerId}
          employeeId={employeeId}
        />
      </Modal>
      <Drawer
        onClose={handleCloseAction}
        title="Terminate Employee"
        opened={action === EmployeePageAction.TERMINATE_EMPLOYEE}
      >
        <TerminateEmployeeForm
          selectedEmployees={[{ ...employee, fullName: getFullName(employee) }]}
          onSuccess={handleCloseAction}
        />
      </Drawer>
      <Drawer
        onClose={handleCloseAction}
        title="Set a leave of absence"
        opened={action === EmployeePageAction.SET_LEAVE_OF_ABSENCE}
      >
        <EmployeeLeaveOfAbsenceForm
          selectedEmployees={[employee]}
          onSuccess={handleCloseAction}
        />
      </Drawer>

      <Drawer
        onClose={handleCloseAction}
        title="Update eligibility"
        opened={action === EmployeePageAction.UPDATE_ELIGIBILITY}
      >
        <UpdateEligibilityForm
          selectedEmployees={[employee]}
          onSuccess={handleCloseAction}
        />
      </Drawer>
      <Drawer
        opened={action === EmployeePageAction.EDIT_EMPLOYEE_ROLE}
        title="Change employee role"
        onClose={handleCloseAction}
      >
        <EditEmployeeRoleForm
          selectedEmployees={[{ ...employee, role }]}
          onSuccess={handleCloseAction}
        />
      </Drawer>
      <Drawer
        opened={action === EmployeePageAction.EDIT_ID_FROM_EMPLOYER}
        title="Change employee ID"
        onClose={handleCloseAction}
      >
        <EditIdFromEmployerForm
          onClose={handleCloseAction}
          employeeId={employeeId}
        />
      </Drawer>
      <Drawer
        opened={action === EmployeePageAction.ADD_ENROLLMENT}
        title="Add enrollment record"
        onClose={handleCloseAction}
      >
        <AddEnrollmentRecordForm
          employerId={employerId}
          onSuccess={(onboardingPeriodId) => {
            setAction(undefined);
            onboardingPeriodId
              ? navigateToRoute('EMPLOYEE_ENROLLMENT_TAB', [
                  { employerId, employeeId, onboardingPeriodId },
                ])
              : navigateToRoute('EMPLOYEE_PAGE', [{ employerId, employeeId }]);
          }}
          selectedEmployees={[
            {
              id: employeeId,
              firstName: employee.firstName,
              lastName: employee.lastName,
            },
          ]}
        />
      </Drawer>
      {onboardingPeriodId && (
        <Drawer
          opened={action === EmployeePageAction.EDIT_ENROLLMENT}
          title="Edit enrollment record"
          onClose={handleCloseAction}
        >
          <EditEnrollmentsRecord
            onClose={handleCloseAction}
            employerId={employerId}
            selectedEmployees={[
              {
                employeeId,
                firstName: employee.firstName,
                lastName: employee.lastName,
                onboardingPeriodId,
              },
            ]}
          />
        </Drawer>
      )}
      <Modal
        size="md"
        showSeparator={false}
        onClose={handleCloseAction}
        opened={action === EmployeePageAction.DELETE_ENROLLMENT}
        title={
          <ModalTitle
            title="Delete enrollment record?"
            icon={<Icon icon={IconTrash} size={24} />}
            bgIcon="zorroFire.3"
            titleOrder={2}
          />
        }
      >
        <DeleteEnrollmentRecord
          onSubmit={() =>
            navigateToRoute('EMPLOYEE_PAGE', [{ employerId, employeeId }])
          }
          onboardingPeriodId={onboardingPeriodId}
          onClose={handleCloseAction}
        />
      </Modal>
    </>
  );
};
