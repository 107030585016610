import { IconEdit } from '@tabler/icons-react';
import {
  EMPLOYEE_DYNAMIC_ROUTES,
  useDynamicRouter,
  useRoles,
} from '@zorro/shared/utils';
import { Menu, Text } from '@zorro/zorro-ui-design';

import { ActionsSubMenu } from '../ActionsSubMenu';
import { getOnboardingPeriodsStatus } from '../actionsMenu.utils';
import { EmployeeActionsOverlays } from '../employeeActions.consts';
import { ActionsMenuSection } from '../employeeActions.types';

const EnrollmentActionsSection = ({
  setOverlayId,
  selectedEmployees,
}: ActionsMenuSection) => {
  const { navigateToRoute } = useDynamicRouter(EMPLOYEE_DYNAMIC_ROUTES);
  const { isZorroOperations } = useRoles();

  const isBulk = selectedEmployees.length > 1;

  const {
    areAllOnboardingPeriodsExist,
    areAllOnboardingPeriodsSubmitted,
    areAnyWithBenefitEnrollments,
  } = getOnboardingPeriodsStatus(selectedEmployees);

  return (
    <ActionsSubMenu title="Enrollment actions" icon={IconEdit}>
      <Menu.Item
        disabled={!areAllOnboardingPeriodsExist || areAnyWithBenefitEnrollments}
        onClick={() =>
          setOverlayId(EmployeeActionsOverlays.CHANGE_ELECTION_WINDOW)
        }
      >
        <Text size="sm">{EmployeeActionsOverlays.CHANGE_ELECTION_WINDOW}</Text>
      </Menu.Item>
      {!isBulk && isZorroOperations && (
        <Menu.Item
          disabled={
            !areAllOnboardingPeriodsExist || !areAnyWithBenefitEnrollments
          }
          onClick={() => setOverlayId(EmployeeActionsOverlays.RESET_ELECTION)}
        >
          <Text size="sm">{EmployeeActionsOverlays.RESET_ELECTION}</Text>
        </Menu.Item>
      )}
      <Menu.Item
        disabled={!areAllOnboardingPeriodsExist || areAnyWithBenefitEnrollments}
        onClick={() => setOverlayId(EmployeeActionsOverlays.WAIVE_COVERAGE)}
      >
        <Text size="sm">{EmployeeActionsOverlays.WAIVE_COVERAGE}</Text>
      </Menu.Item>
      {isZorroOperations && (
        <Menu.Item
          disabled={
            !areAllOnboardingPeriodsExist || areAnyWithBenefitEnrollments
          }
          onClick={() =>
            setOverlayId(EmployeeActionsOverlays.CHANGE_SHOPPING_STATUS)
          }
        >
          <Text size="sm">
            {EmployeeActionsOverlays.CHANGE_SHOPPING_STATUS}
          </Text>
        </Menu.Item>
      )}
      {isBulk && (
        <>
          <Menu.Item
            disabled={!areAllOnboardingPeriodsSubmitted}
            onClick={() =>
              setOverlayId(
                EmployeeActionsOverlays.BULK_FLAG_AS_APPLICATION_SENT
              )
            }
          >
            <Text size="sm">
              {EmployeeActionsOverlays.BULK_FLAG_AS_APPLICATION_SENT}
            </Text>
          </Menu.Item>
          <Menu.Item
            disabled={!areAllOnboardingPeriodsSubmitted}
            onClick={() =>
              setOverlayId(EmployeeActionsOverlays.BULK_CONFIRM_ENROLLMENT)
            }
          >
            <Text size="sm">
              {EmployeeActionsOverlays.BULK_CONFIRM_ENROLLMENT}
            </Text>
          </Menu.Item>
        </>
      )}
      {!isBulk && (
        <Menu.Item
          onClick={() => {
            navigateToRoute('EMPLOYEE_PAGE', [
              {
                employerId: selectedEmployees[0].employerId,
                employeeId: selectedEmployees[0].id,
              },
            ]);
          }}
        >
          <Text size="sm">Update enrollment status</Text>
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() =>
          setOverlayId(EmployeeActionsOverlays.ADD_ENROLLMENT_RECORD)
        }
      >
        <Text size="sm">{EmployeeActionsOverlays.ADD_ENROLLMENT_RECORD}</Text>
      </Menu.Item>
    </ActionsSubMenu>
  );
};

export { EnrollmentActionsSection };
