import { IconMail } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { useDynamicRouter } from '@zorro/shared/utils';
import { Menu, Text } from '@zorro/zorro-ui-design';

import { useLoadingOverlay } from '../../LoadingOverlayContext';
import { ActionsSubMenu } from '../ActionsSubMenu';
import {
  calculateElectionWindowLaunchStatus,
  getOnboardingPeriodsStatus,
} from '../actionsMenu.utils';
import { ActionsMenuSection } from '../employeeActions.types';
import {
  sendAccountActivationEmails,
  sendElectionDeadlineReminderEmails,
  sendElectionSummaryEmails,
  sendElectionWindowLaunchEmails,
  sendWaiverConfirmationEmails,
} from './emailCommunications.utils';

const EmailCommunicationsSection = ({
  selectedEmployees,
}: ActionsMenuSection) => {
  const { startLoading, stopLoading } = useLoadingOverlay();

  const { reloadPage } = useDynamicRouter({});
  const queryClient = useQueryClient();

  const {
    areAllOnboardingPeriodsActive,
    areAllOnboardingPeriodsSubmitted,
    areAllOnboardingPeriodsWaived,
  } = getOnboardingPeriodsStatus(selectedEmployees);

  const isElectionWindowLaunch =
    calculateElectionWindowLaunchStatus(selectedEmployees);

  return (
    <ActionsSubMenu title="Email communication" icon={IconMail}>
      <Menu.Item
        onClick={async () => {
          startLoading();
          await sendAccountActivationEmails(selectedEmployees);
          await queryClient.invalidateQueries();
          reloadPage();
          stopLoading();
        }}
      >
        <Text size="sm">Account invitation</Text>
      </Menu.Item>
      <Menu.Item
        disabled={!isElectionWindowLaunch}
        onClick={async () => {
          startLoading();
          await sendElectionWindowLaunchEmails(selectedEmployees);
          stopLoading();
        }}
      >
        <Text size="sm">Election window launch</Text>
      </Menu.Item>
      <Menu.Item
        disabled={!areAllOnboardingPeriodsActive}
        onClick={async () => {
          startLoading();
          await sendElectionDeadlineReminderEmails(selectedEmployees);
          stopLoading();
        }}
      >
        <Text size="sm">Election deadline reminder</Text>
      </Menu.Item>
      <Menu.Item
        disabled={!areAllOnboardingPeriodsSubmitted}
        onClick={async () => {
          startLoading();
          await sendElectionSummaryEmails(selectedEmployees);
          stopLoading();
        }}
      >
        <Text size="sm">Election summary</Text>
      </Menu.Item>
      <Menu.Item
        disabled={!areAllOnboardingPeriodsWaived}
        onClick={async () => {
          startLoading();
          await sendWaiverConfirmationEmails(selectedEmployees);
          stopLoading();
        }}
      >
        <Text size="sm">Waiver confirmation</Text>
      </Menu.Item>
    </ActionsSubMenu>
  );
};

export { EmailCommunicationsSection };
