import { TextInput, TextInputProps } from '@mantine/core';
import { forwardRef } from 'react';

import classNames from './TextInput.module.scss';

export type ITextInputProps = Omit<TextInputProps, 'value'> & {
  value?: string;
  masked?: boolean;
};

export const ZorroTextInput = forwardRef<HTMLInputElement, ITextInputProps>(
  ({ size = 'md', disabled = false, masked = false, ...props }, ref) => {
    const inputClassNames = [
      classNames.input ?? null,
      masked ? 'fs-mask' : null,
    ].filter((className): className is string => Boolean(className));

    return (
      <TextInput
        ref={ref}
        size={size}
        classNames={{
          ...classNames,
          input: inputClassNames.join(' '),
        }}
        disabled={disabled}
        {...props}
      />
    );
  }
);

ZorroTextInput.displayName = 'ZorroTextInput';
