import { useQueryClient } from '@tanstack/react-query';
import {
  KeyedError,
  MonolithMethodNames,
  MonolithMethods,
  callEndpoint,
  getErrorsFromPromises,
  handleBatchNotifications,
  useDynamicRouter,
} from '@zorro/shared/utils';
import _chunk from 'lodash/chunk';
import { useState } from 'react';

import { useLoadingOverlay } from '../LoadingOverlayContext';

export type BatchItem<M extends MonolithMethodNames = MonolithMethodNames> = {
  key: string;
  params: readonly [...Parameters<MonolithMethods[M]>];
};

type UseBatchCallEndpointOptions<M extends MonolithMethodNames> = {
  methodName: M;
  singularItemName: string;
  action: string;
  batchSize?: number;
};

type ExecuteBatchCallOptions = {
  onSuccess?: () => void;
  onError?: () => void;
  shouldAvoidPageReload?: boolean;
};

export const useBatchCallEndpoint = <
  M extends MonolithMethodNames = MonolithMethodNames
>({
  methodName,
  singularItemName,
  action,
  batchSize = 5,
}: UseBatchCallEndpointOptions<M>) => {
  const queryClient = useQueryClient();
  const { reloadPage } = useDynamicRouter({});
  const { startLoading, stopLoading } = useLoadingOverlay();

  const [errors, setErrors] = useState<KeyedError[]>([]);

  const executeBatchCall = async (
    batchItems: BatchItem<M>[],
    options?: ExecuteBatchCallOptions
  ): Promise<{
    data: PromiseSettledResult<unknown>[];
    errors: KeyedError[];
  }> => {
    try {
      startLoading();
      setErrors([]);

      const batches = _chunk(batchItems, batchSize);

      const results: PromiseSettledResult<unknown>[] = [];

      for (const batch of batches) {
        const batchResults = await Promise.allSettled(
          batch.map((item) => {
            return callEndpoint<M>({
              method: methodName,
              params: item.params,
            });
          })
        );

        results.push(...batchResults);
      }

      await queryClient.invalidateQueries();

      if (!options?.shouldAvoidPageReload) reloadPage();

      stopLoading();

      await handleBatchNotifications(
        results,
        { singular: singularItemName },
        action
      );

      const errorsFromPromises = getErrorsFromPromises(results, batchItems);

      setErrors(errorsFromPromises);

      errorsFromPromises.length === 0
        ? options?.onSuccess?.()
        : options?.onError?.();

      return { data: results, errors: errorsFromPromises };
    } catch (error) {
      stopLoading();
      throw error;
    }
  };

  return {
    executeBatchCall,
    errors,
  };
};
