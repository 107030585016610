import { useForwardRef } from '@zorro/shared/utils';
import _noop from 'lodash/noop';
import { ForwardedRef, MutableRefObject, forwardRef } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

import { ITextInputProps, ZorroTextInput } from '../TextInput';

interface IAddressInputProps extends ITextInputProps {
  notifySubscribers?: (addressValue: string) => void;
}

export const ZorroAddressInput = forwardRef(
  (
    { notifySubscribers = _noop, size = 'md', ...props }: IAddressInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const addressInputRef = useForwardRef<HTMLInputElement | null>(ref);

    const handleNotify = (addressValue: string) => {
      notifySubscribers(addressValue);
    };

    const { ref: addressRef } = usePlacesWidget({
      /**
       * TODO: remove '&callback=Function.prototype' from apiKey again once
       * https://github.com/ErrorPro/react-google-autocomplete/issues/200 is resolved
       */
      apiKey: `${process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY}&callback=Function.prototype`,
      options: {
        componentRestrictions: { country: 'us' },
        types: ['address'],
      },
      onPlaceSelected: (place) => {
        if (addressInputRef.current && place?.formatted_address) {
          addressInputRef.current.value = place.formatted_address;
          handleNotify(addressInputRef.current.value);
        }
      },
    });

    return (
      <ZorroTextInput
        ref={(component: HTMLInputElement) => {
          /**
           * INFO: We want to avoid firing requests against the Google Places API
           * when running automated e2e tests to keep our usage and costs low.
           * Since our e2e tests just type out the full addresses into a text field
           * and don't rely on the autocompletion, this should be fine.
           * See more: https://app.clickup.com/t/860pr92qn
           */
          if (
            process.env.NEXT_PUBLIC_IS_E2E_RUN !== 'true' &&
            component instanceof HTMLInputElement
          ) {
            addressInputRef.current = component;
            (addressRef as MutableRefObject<HTMLInputElement | null>).current =
              component;
          }
        }}
        {...props}
      />
    );
  }
);

ZorroAddressInput.displayName = 'ZorroAddressInput';
