import { brand } from '../../theme';
import { ITableProps, ZorroTable } from '../Table/Table';
import { ZorroNewTableSortIcon } from './NewTableSortIcon';

export function NewZorroTable({
  sortStatus = { columnAccessor: '', direction: 'asc' },
  onSortStatusChange,
  fetching = false,
  ...props
}: ITableProps) {
  return (
    <ZorroTable
      {...props}
      sortStatus={sortStatus}
      onSortStatusChange={onSortStatusChange}
      highlightOnHover
      highlightOnHoverColor={brand.zorroGray50}
      fetching={fetching}
      sortIcons={{
        sorted: <ZorroNewTableSortIcon direction={sortStatus.direction} />,
        unsorted: <ZorroNewTableSortIcon color={brand.zorroGray300} />,
      }}
    />
  );
}
