import { EmployeeDto, UserAndTenantDto } from '@zorro/clients';
import {
  SUCCESS_MESSAGES,
  callEndpoint,
  responseErrorToString,
  showErrorNotification,
  showSuccessNotification,
} from '@zorro/shared/utils';

import { ActionsMenuSection } from '../employeeActions.types';

type TSendAccountActivationEmailsSelectedEmployees = {
  userId: EmployeeDto['userId'];
  employerId: EmployeeDto['employerId'];
}[];

export async function sendAccountActivationEmails(
  selectedEmployees: TSendAccountActivationEmailsSelectedEmployees
) {
  const userIds: UserAndTenantDto[] = selectedEmployees.map((employeeRow) => ({
    id: employeeRow.userId,
    tenantId: employeeRow.employerId,
  }));

  try {
    await callEndpoint({
      method: 'usersControllerSendActivationEmails',
      params: [userIds],
    });

    showSuccessNotification({
      message: SUCCESS_MESSAGES.getEmailSentSuccessfullyMessage(
        selectedEmployees.length
      ),
    });
  } catch (error) {
    showErrorNotification({ message: responseErrorToString(error) });
  }
}

export async function sendElectionWindowLaunchEmails(
  selectedEmployees: ActionsMenuSection['selectedEmployees']
) {
  const onboardingPeriodsIds: string[] = selectedEmployees
    .map((employeeRow) => employeeRow.onboardingPeriodId)
    .filter((onboardingPeriodId) => onboardingPeriodId !== undefined);

  try {
    await Promise.all(
      onboardingPeriodsIds.map(async (onboardingPeriodId) =>
        callEndpoint({
          method: 'onboardingPeriodsControllerSendElectionWindowLaunchEmail',
          params: [onboardingPeriodId],
        })
      )
    );

    showSuccessNotification({
      message: SUCCESS_MESSAGES.getEmailSentSuccessfullyMessage(
        selectedEmployees.length
      ),
    });
  } catch (error) {
    showErrorNotification({ message: responseErrorToString(error) });
  }
}

export async function sendElectionDeadlineReminderEmails(
  selectedEmployees: ActionsMenuSection['selectedEmployees']
) {
  const onboardingPeriodsIds: string[] = selectedEmployees
    .map((employeeRow) => employeeRow.onboardingPeriodId)
    .filter((onboardingPeriodId) => onboardingPeriodId !== undefined);

  try {
    await Promise.all(
      onboardingPeriodsIds.map(async (onboardingPeriodId) =>
        callEndpoint({
          method: 'onboardingPeriodsControllerSendElectionReminder',
          params: [onboardingPeriodId],
        })
      )
    );

    showSuccessNotification({
      message: SUCCESS_MESSAGES.getEmailSentSuccessfullyMessage(
        selectedEmployees.length
      ),
    });
  } catch (error) {
    showErrorNotification({ message: responseErrorToString(error) });
  }
}

export async function sendElectionSummaryEmails(
  selectedEmployees: ActionsMenuSection['selectedEmployees']
) {
  const onboardingPeriodsIds: string[] = selectedEmployees
    .map((employeeRow) => employeeRow.onboardingPeriodId)
    .filter((onboardingPeriodId) => onboardingPeriodId !== undefined);

  try {
    await Promise.all(
      onboardingPeriodsIds.map(async (onboardingPeriodId) =>
        callEndpoint({
          method: 'onboardingPeriodsControllerSendSummaryEmail',
          params: [onboardingPeriodId],
        })
      )
    );

    showSuccessNotification({
      message: SUCCESS_MESSAGES.getEmailSentSuccessfullyMessage(
        selectedEmployees.length
      ),
    });
  } catch (error) {
    showErrorNotification({ message: responseErrorToString(error) });
  }
}

export async function sendWaiverConfirmationEmails(
  selectedEmployees: ActionsMenuSection['selectedEmployees']
) {
  const employeesIds: string[] = selectedEmployees
    .map((employeeRow) => employeeRow.id)
    .filter((onboardingPeriodId) => onboardingPeriodId !== undefined);

  try {
    await Promise.all(
      employeesIds.map(async (employeeId) =>
        callEndpoint({
          method: 'onboardingPeriodsControllerSendWaiverConfirmation',
          params: [employeeId],
        })
      )
    );

    showSuccessNotification({
      message: SUCCESS_MESSAGES.getEmailSentSuccessfullyMessage(
        selectedEmployees.length
      ),
    });
  } catch (error) {
    showErrorNotification({ message: responseErrorToString(error) });
  }
}
