import { Stack, Table, Text } from '@zorro/zorro-ui-design';

import { ModalFooter } from '../../SimpleActionModal';
import { useBatchCallEndpoint } from '../../hooks';
import { ErrorsTable } from '../Errors/ErrorsTable';

export type DeleteEmployeeRow = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

export type DeleteEmployeeModalProps = {
  selectedEmployees: DeleteEmployeeRow[];
  onClose: () => void;
  onSuccess: () => void;
};

export const DeleteEmployeeForm = ({
  selectedEmployees,
  onClose,
  onSuccess,
}: DeleteEmployeeModalProps) => {
  const { executeBatchCall, errors } = useBatchCallEndpoint({
    methodName: 'employeesControllerDelete',
    singularItemName: 'employee',
    action: 'deleted',
    batchSize: 5,
  });

  const deleteEmployees = async () => {
    const batchItems = selectedEmployees.map(({ id, firstName, lastName }) => ({
      key: `${firstName} ${lastName}`,
      params: [id] as const,
    }));

    await executeBatchCall(batchItems, { onSuccess });
  };

  async function handleSubmit() {
    await deleteEmployees();
  }

  return (
    <Stack>
      {errors.length > 0 ? (
        <ErrorsTable
          tableTitle="Employee name"
          errors={errors}
          isBulk={selectedEmployees?.length > 1}
        />
      ) : (
        <Stack>
          <Text>
            This will permanently delete the following employees. Are you sure
            you want to continue?
          </Text>
          <Table
            columns={[
              { accessor: 'fullName' },
              { accessor: 'email' },
              { accessor: 'id' },
            ]}
            records={selectedEmployees}
          />
          <ModalFooter
            onSubmit={handleSubmit}
            onClose={onClose}
            submitText="Delete employees?"
            submitVariant="primary-red"
          />
        </Stack>
      )}
    </Stack>
  );
};
