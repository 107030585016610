export enum EmployeeActionsOverlays {
  NONE = '',

  // employee management

  ADD_EMPLOYEE = 'Add a new employee',
  UPLOAD_ROSTER = 'Upload roster',

  // email communications

  // enrollment actions (NOT SHOWN IN DON)

  CHANGE_ELECTION_WINDOW = 'Change election window',
  RESET_ELECTION = 'Reset election',
  WAIVE_COVERAGE = 'Waive coverage',
  CHANGE_SHOPPING_STATUS = 'Change shopping status',
  BULK_FLAG_AS_APPLICATION_SENT = 'Flag as application sent',
  BULK_CONFIRM_ENROLLMENT = 'Confirm enrollment',
  ADD_ENROLLMENT_RECORD = 'Add a new enrollment record',

  // employee administration

  SINGLE_TERMINATE_EMPLOYEE = 'Terminate employee',
  SINGLE_SET_LEAVE_OF_ABSENCE = 'Set a leave of absence',
  UPDATE_ELIGIBILITY = 'Update eligibility',
  CHANGE_EMPLOYEE_CLASS = 'Change class',

  // account management

  CHANGE_EMPLOYEE_ROLE = 'Change user role',
  DELETE_EMPLOYEE = 'Delete employee',
}

export const menuOffset = 6;
