import { Menu, MenuProps } from '@mantine/core';

import classNames from './Menu.module.scss';
import { ZorroMenuDivider } from './MenuDivider';
import { ZorroMenuDropdown } from './MenuDropdown';
import { ZorroMenuItem } from './MenuItem';
import { ZorroMenuLabel } from './MenuLabel';
import { ZorroMenuTarget } from './MenuTarget';

export type IMenuProps = MenuProps;

export function ZorroMenu({
  withArrow = false,
  arrowSize = 14,
  offset = 0,
  position = 'bottom-end',
  shadow = 'sm',
  ...props
}: IMenuProps) {
  return (
    <Menu
      withArrow={withArrow}
      arrowSize={arrowSize}
      offset={offset}
      position={position}
      classNames={classNames}
      shadow={shadow}
      {...props}
    />
  );
}

ZorroMenu.Item = ZorroMenuItem;
ZorroMenu.Label = ZorroMenuLabel;
ZorroMenu.Dropdown = ZorroMenuDropdown;
ZorroMenu.Target = ZorroMenuTarget;
ZorroMenu.Divider = ZorroMenuDivider;
