import axios from 'axios';
import { IncomingMessage } from 'node:http';

export function getIp(req: IncomingMessage) {
  const xForwardedForHeaderValue = req.headers['x-forwarded-for'];
  return typeof xForwardedForHeaderValue === 'string'
    ? xForwardedForHeaderValue?.split(/, /u)[0]
    : req.socket.remoteAddress;
}

export async function getExternalIP(): Promise<string> {
  try {
    const response = await axios.get('https://checkip.amazonaws.com');
    return response.data.trim();
  } catch {
    return '';
  }
}
