import { Button, Flex } from '@zorro/zorro-ui-design';

type Props = {
  onClose: () => void;
  onSubmit: () => void;
  submitVariant: string;
  submitText: string;
};

export const ModalFooter = ({
  onClose,
  onSubmit,
  submitVariant,
  submitText,
}: Props) => {
  return (
    <Flex justify="flex-end" gap="sm">
      <Button onClick={onClose} color="black" variant="tertiary" size="md">
        Cancel
      </Button>
      <Button onClick={onSubmit} size="md" variant={submitVariant}>
        {submitText}
      </Button>
    </Flex>
  );
};
