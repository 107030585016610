import { yupResolver } from '@hookform/resolvers/yup';
import {
  EmployerContributionDto,
  InsuredPeopleDto,
  OnboardingPeriodDto,
  SelfReportType,
  SubmissionType,
} from '@zorro/clients';
import {
  UNEXPECTED_ERROR_MESSAGE,
  callEndpoint,
  showErrorNotification,
  useForm,
  useMonolithQuery,
} from '@zorro/shared/utils';
import { UseFormReturn } from 'react-hook-form';

import { useMonolithMutation } from '../hooks';
import {
  MedicalPlanFormFields,
  MedicalPlanItemFormFields,
  formatExtendedPlanDetails,
  getDefaultMedicalPlanFormFields,
  getDefaultWhoIsEnrolled,
  getFamilyUnitFromWhoIsEnrolled,
  getWhoIsEnrolled,
  medicalPlanFormSchema,
} from './MedicalPlansFormUtils';

type UseMedicalPlanForm = (props: {
  onboardingPeriodId: string;
  employeeId: string;
}) => {
  defaultMedicalPlanFormFields: MedicalPlanItemFormFields;
  medicalPlanForm: UseFormReturn<MedicalPlanFormFields>;
  onboardingPeriod?: OnboardingPeriodDto;
  whoIsEnrolled: string[];
  insured?: InsuredPeopleDto;
  onSubmit: () => Promise<boolean>;
};

export const useMedicalPlanForm: UseMedicalPlanForm = ({
  employeeId,
  onboardingPeriodId,
}) => {
  const { mutate: mutateMajorMedical } = useMonolithMutation({
    method: 'majorMedicalControllerUpsertMajorMedicalPlanByOperations',
  });
  const { mutate: mutateSubmitOnboardingChoices } = useMonolithMutation({
    method: 'onboardingPeriodsControllerSubmitOnboardingChoices',
  });
  const { mutate: mutateOnboardingPeriod } = useMonolithMutation({
    method: 'onboardingPeriodsControllerUpdateByAdmin',
    shouldInvalidateQueries: false,
    shouldReloadPage: false,
  });

  const { data: onboardingPeriod } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindOne',
    params: [employeeId, onboardingPeriodId],
  });

  const { data: benefits } = useMonolithQuery({
    method: 'benefitsControllerGetBenefits',
    params: [onboardingPeriodId],
  });

  const { data: insured } = useMonolithQuery({
    method: 'insuredControllerFindInsuredForPeriod',
    params: [onboardingPeriodId],
  });

  const majorMedicalPlan = benefits?.majorMedicalBenefit?.plan;
  const benefitEnrollment = benefits?.majorMedicalBenefit?.benefitEnrollment;
  const whoIsEnrolled = insured ? getWhoIsEnrolled(insured) : [];
  const defaultWhoIsEnrolled = insured
    ? getDefaultWhoIsEnrolled(
        insured,
        benefits?.majorMedicalBenefit?.familyUnit ?? undefined
      )
    : [];

  const defaultMedicalPlanFormFields = getDefaultMedicalPlanFormFields(
    defaultWhoIsEnrolled,
    majorMedicalPlan?.externalID,
    majorMedicalPlan?.name,
    majorMedicalPlan?.carrierName,
    majorMedicalPlan?.premium
  );

  const medicalPlanForm = useForm<MedicalPlanFormFields>({
    mode: 'all',
    resolver: yupResolver(medicalPlanFormSchema),
    defaultValues: { plans: [defaultMedicalPlanFormFields] },
  });

  return {
    insured,
    whoIsEnrolled,
    medicalPlanForm,
    onboardingPeriod,
    defaultMedicalPlanFormFields,
    onSubmit: async () => {
      if (!insured || !onboardingPeriod) {
        showErrorNotification({ message: UNEXPECTED_ERROR_MESSAGE });
        return false;
      }

      const { plans } = medicalPlanForm.getValues();
      const selectedEnrolled = new Set(
        plans.flatMap((plan) => plan.whoIsEnrolled)
      );
      if (
        !selectedEnrolled.has(
          `${insured.employee.firstName} ${insured.employee.lastName}`
        )
      ) {
        showErrorNotification({
          message: 'The employee must be listed on a plan',
        });
        return false;
      }
      if (insured.dependents.length > 0) {
        const allDependents = insured.dependents.map(
          ({ firstName, lastName }) => `${firstName} ${lastName}`
        );

        let doesNotIncludeAllDependents = false;
        let includesAnyDependent = false;
        allDependents.forEach((dependent) => {
          if (selectedEnrolled.has(dependent)) {
            includesAnyDependent = true;
          }
          if (!selectedEnrolled.has(dependent)) {
            doesNotIncludeAllDependents = true;
          }
        });

        if (includesAnyDependent && doesNotIncludeAllDependents) {
          showErrorNotification({
            message: 'All children must be included or excluded together',
          });
          return false;
        }
      }

      const calculatedFamilyUnit = getFamilyUnitFromWhoIsEnrolled(
        insured,
        selectedEnrolled
      );

      let employerContribution: EmployerContributionDto;
      try {
        employerContribution = await callEndpoint({
          method:
            'contributionModelsControllerFindEmployeesEmployerContribution',
          params: [onboardingPeriodId, calculatedFamilyUnit],
        });
      } catch {
        showErrorNotification({
          message:
            "Allowance not found. Please validate the class matches the company's allowance model",
        });
        return false;
      }

      const premium = plans.reduce((prev, plan) => prev + plan.premium, 0);
      const employerMonthlyContribution = Math.min(
        employerContribution.stipend,
        premium
      );
      const employeeMonthlyContribution = Math.max(
        premium - employerMonthlyContribution,
        0
      );
      const extendedPlanDetails = formatExtendedPlanDetails(plans);
      const carrierName = plans.map((plan) => plan.carrier).join(' + ');
      const planName = plans.map((plan) => plan.planName).join(' + ');
      const planIds = plans.map((plan) => plan.planId).join(' + ');
      const maxOutOfPocket =
        plans.find((plan) => plan.maxOutOfPocket)?.maxOutOfPocket ?? null;
      const isHsaEligible =
        plans.find((plan) => plan.isHsaEligible)?.isHsaEligible ?? null;
      const benefitsSummaryUrl =
        plans.find((plan) => plan.benefitsSummaryUrl)?.benefitsSummaryUrl ??
        null;
      const deductible =
        plans.find((plan) => plan.deductible)?.deductible ?? null;

      try {
        if (!onboardingPeriod.isActive && !benefitEnrollment) {
          await mutateOnboardingPeriod([
            onboardingPeriodId,
            {
              submissionType: SubmissionType.BY_OPERATOR,
              isActive: true,
              shouldCreateEnrollments: true,
            },
          ]);
        }

        await mutateMajorMedical([
          onboardingPeriodId,
          true,
          {
            updateManuallyEnteredMajorMedicalPlan: {
              selfReportType: SelfReportType.NOT_APPLICABLE,
              allowance: employerContribution.stipend,
              isCombinedPlan: plans.length > 1,
              familyUnit: calculatedFamilyUnit,
              employeeMonthlyContribution,
              externalID: planIds,
              extendedPlanDetails,
              benefitsSummaryUrl,
              maxOutOfPocket,
              isHsaEligible,
              name: planName,
              carrierName,
              deductible,
              premium,
            },
          },
        ]);

        if (!benefitEnrollment) {
          await mutateSubmitOnboardingChoices([
            employeeId,
            { submissionType: SubmissionType.BY_OPERATOR, isActive: false },
          ]);
        }

        return true;
      } catch {
        /* empty */
      }
      return false;
    },
  };
};
