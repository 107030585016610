import { Select, SelectProps } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { ForwardedRef, forwardRef } from 'react';

import { ZorroIcon } from '../Icon';
import { brand } from '../theme';
import classNames from './Select.module.scss';

export type ISelectProps = Omit<SelectProps, 'variant'> & {
  variant?: 'default' | 'filled' | 'unstyled';
};

export const ZorroSelect = forwardRef(
  (
    {
      variant = 'default',
      size = 'md',
      nothingFoundMessage = 'No results found',
      color = brand.zorroGray600,
      ...props
    }: ISelectProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Select
        variant={variant}
        size={size}
        nothingFoundMessage={nothingFoundMessage}
        /**
         * clearable prop only works when rightSection prop is not set
         * See more: https://mantine.dev/core/select/#right-section
         */
        rightSection={
          props.clearable ? undefined : <ZorroIcon icon={IconChevronDown} />
        }
        withCheckIcon={false}
        classNames={classNames}
        color={color}
        ref={ref}
        {...props}
      />
    );
  }
);

ZorroSelect.displayName = 'Select';
