/*
 * INFO: This regex will match a 'uuid/filename' for example the line:
 * https://s3.amazonaws.com/zorro-employer-documents-test/629c5b25-7dde-4a1f-857f-3ee2bef47249/turkey.csv
 * will match '629c5b25-7dde-4a1f-857f-3ee2bef47249/turkey.csv'
 */
export function extractFileKeyFromUrl(url: string) {
  const regex =
    /[\da-fA-F]{8}-[\da-fA-F]{4}-[\da-fA-F]{4}-[\da-fA-F]{4}-[\da-fA-F]{12}.*/u;

  const match = url.match(regex);
  return match?.[0] ? encodeURIComponent(match?.[0]) : undefined;
}
