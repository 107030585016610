import { YesNo } from '@zorro/types';
import {
  Group,
  IRadioGroupProps,
  Radio,
  RadioGroup,
} from '@zorro/zorro-ui-design';
import { ForwardedRef, forwardRef } from 'react';
import { ControllerRenderProps, Path } from 'react-hook-form';

type AleFieldValues = { isApplicableLargeEmployer?: YesNo | null };
type Props<T extends AleFieldValues, U extends Path<T>> = ControllerRenderProps<
  T,
  U
> & {
  radioGroupProps?: Omit<IRadioGroupProps, 'children'>;
};

export const ApplicableLargeEmployerInput = forwardRef(
  <T extends AleFieldValues, U extends Path<T>>(
    { radioGroupProps, disabled, ...field }: Props<T, U>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <RadioGroup
        {...field}
        {...radioGroupProps}
        ref={ref}
        display="flex"
        label="Is the company considered an Applicable Large Employer (ALE)?"
        styles={{
          root: { alignItems: 'start', flexDirection: 'column' },
          label: { alignSelf: 'start' },
        }}
      >
        <Group>
          <Radio
            value={YesNo.YES}
            label="Yes, it's an ALE"
            shouldHaveBorder={false}
            disabled={disabled}
          />
          <Radio
            value={YesNo.NO}
            label="No, it's not an ALE"
            shouldHaveBorder={false}
            disabled={disabled}
          />
        </Group>
      </RadioGroup>
    );
  }
);

ApplicableLargeEmployerInput.displayName = 'ApplicableLargeEmployerInput';
