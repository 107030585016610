import { IconDownload, IconTrash } from '@tabler/icons-react';
import { DocumentsIcon } from '@zorro/shared/assets';
import { downloadEmployerDocument } from '@zorro/shared/utils';
import { Card, Flex, Space, Text } from '@zorro/zorro-ui-design';

import styles from './DownloadableDocument.module.css';

interface DownloadableDocumentProps {
  name: string;
  documentLocationUrl: string;
  onDelete?: () => void;
  canDelete: boolean;
  onDownload?: () => void;
}

export const DownloadableDocument = ({
  name,
  documentLocationUrl,
  onDelete,
  canDelete,
  onDownload = async () => {
    await downloadEmployerDocument(documentLocationUrl, name);
  },
}: DownloadableDocumentProps) => {
  return (
    <Card key={name}>
      <Flex justify="space-between" align="center" w="100%">
        <Flex
          justify="space-evenly"
          align="center"
          style={{ overflow: 'hidden' }}
        >
          <DocumentsIcon />
          <Space w="md" />
          <Text mr="sm">{name}</Text>
        </Flex>
        <Flex align="flex-end">
          <IconDownload onClick={onDownload} className={styles.iconDownload} />
          <>
            <Space w="sm" />
            {canDelete && (
              <IconTrash className={styles.iconTrash} onClick={onDelete} />
            )}
          </>
        </Flex>
      </Flex>
    </Card>
  );
};
