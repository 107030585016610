import { IconX } from '@tabler/icons-react';
import { ActionIcon, Box, Group, brand } from '@zorro/zorro-ui-design';

import { FileUploadDisplay } from '../FileUploadDisplay';

type Props = {
  children?: React.ReactNode;
  name?: string;
  onClickDelete: () => void;
  onClickDownload?: () => void;
};

/**
 * UploadedFileBox
 * @param name - if children is provided, name is not needed
 */

export const UploadedFileBox = ({
  children,
  onClickDelete,
  name,
  onClickDownload,
}: Props) => {
  return (
    <Box
      bg="zorroGray.1"
      px="xs"
      py="xs"
      style={(theme) => ({ borderRadius: theme.radius.sm, width: '100%' })}
    >
      <Group
        align="center"
        justify="space-between"
        display="flex"
        wrap="nowrap"
        gap={0}
      >
        {children || (
          <FileUploadDisplay onClick={onClickDownload} fileName={name ?? ''} />
        )}

        <ActionIcon variant="transparent" onClick={onClickDelete}>
          <IconX size={24} color={brand.zorroCoal900} />
        </ActionIcon>
      </Group>
    </Box>
  );
};
