import { IconUser } from '@tabler/icons-react';
import { useRoles } from '@zorro/shared/utils';
import { Menu, Text } from '@zorro/zorro-ui-design';

import { ActionsSubMenu } from '../ActionsSubMenu';
import { EmployeeActionsOverlays } from '../employeeActions.consts';
import { ActionsMenuSection } from '../employeeActions.types';

const EmployeeAdministrationSection = ({
  setOverlayId,
  selectedEmployees,
}: ActionsMenuSection) => {
  const { isZorroOperations } = useRoles();

  const isBulk = selectedEmployees.length > 1;

  return (
    <ActionsSubMenu title="Employee administration" icon={IconUser}>
      {!isBulk && (
        <>
          <Menu.Item
            onClick={() =>
              setOverlayId(EmployeeActionsOverlays.SINGLE_TERMINATE_EMPLOYEE)
            }
          >
            <Text size="sm">
              {EmployeeActionsOverlays.SINGLE_TERMINATE_EMPLOYEE}
            </Text>
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              setOverlayId(EmployeeActionsOverlays.SINGLE_SET_LEAVE_OF_ABSENCE)
            }
          >
            <Text size="sm">
              {EmployeeActionsOverlays.SINGLE_SET_LEAVE_OF_ABSENCE}
            </Text>
          </Menu.Item>
        </>
      )}
      {isZorroOperations && (
        <Menu.Item
          onClick={() =>
            setOverlayId(EmployeeActionsOverlays.UPDATE_ELIGIBILITY)
          }
        >
          <Text size="sm">{EmployeeActionsOverlays.UPDATE_ELIGIBILITY}</Text>
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() =>
          setOverlayId(EmployeeActionsOverlays.CHANGE_EMPLOYEE_CLASS)
        }
      >
        <Text size="sm">{EmployeeActionsOverlays.CHANGE_EMPLOYEE_CLASS}</Text>
      </Menu.Item>
    </ActionsSubMenu>
  );
};

export { EmployeeAdministrationSection };
