import { IconHelp } from '@tabler/icons-react';
import { ReactNode } from 'react';

import { ZorroGroup } from '../Group';
import { ZorroIcon } from '../Icon';
import { ZorroText } from '../Text';
import { ZorroTooltip } from '../Tooltip';

type Props = {
  label: string;
  tooltipLabel: ReactNode;
  isRequired?: boolean;
};

export const ZorroLabelWithTooltip = ({
  label,
  tooltipLabel,
  isRequired,
}: Props) => {
  return (
    <ZorroGroup gap="0.25rem" h="1.25rem">
      <ZorroText size="sm">
        {label}
        {isRequired ? ' *' : ''}
      </ZorroText>
      <ZorroTooltip label={tooltipLabel} position="top">
        <ZorroGroup pos="relative">
          <ZorroIcon icon={IconHelp} size="1rem" color="#676979" />
        </ZorroGroup>
      </ZorroTooltip>
    </ZorroGroup>
  );
};
