import { IconCopy, IconPencil } from '@tabler/icons-react';
import { ThreeDotsIcon } from '@zorro/shared/assets';
import {
  ZORRO_UI_DYNAMIC_ROUTES,
  createImpersonationSession,
  getFullName,
  showSuccessNotification,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import { rolesLabelConfig } from '@zorro/types';
import {
  ActionIcon,
  Box,
  Divider,
  Group,
  Icon,
  Menu,
  Stack,
  Text,
  brand,
} from '@zorro/zorro-ui-design';
import CopyToClipboard from 'react-copy-to-clipboard';

import { EligibilityStatusBadge } from '../../EligibilityStatusBadge';
import { resetPassword } from '../../EmployeeActions/AccountManagement/accountManagement.utils';
import { sendAccountActivationEmails } from '../../EmployeeActions/EmailCommunications/emailCommunications.utils';
import { UserAvatar } from '../../UserAvatar';
import { EmployeePageAction } from '../EmployeePageActions';
import { getEmployeePageRole } from '../EmployeePageUtils';

export enum SidebarHeaderAction {
  EDIT_COMPANY_EMAIL = 'EDIT_COMPANY_EMAIL',
  DELETE_EMPLOYEE = 'DELETE_EMPLOYEE',
  TERMINATE_EMPLOYEE = 'TERMINATE_EMPLOYEE',
  SET_LEAVE_OF_ABSENCE = 'SET_LEAVE_OF_ABSENCE',
  UPDATE_ELIGIBILITY = 'UPDATE_ELIGIBILITY',
}

type Props = {
  employerId: string;
  employeeId: string;
  setAction: (action: EmployeePageAction) => void;
};

export const EmployeePageSidebarHeader = ({
  employerId,
  employeeId,
  setAction,
}: Props) => {
  const { isZorroOperations } = useRoles();

  const { data: employee, isLoading: isLoadingEmployee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });

  const { data: userRoles, isLoading: isLoadingRoles } = useMonolithQuery({
    method: 'usersControllerGetUserRoles',
    params: employee && [employerId, employee.userId],
  });

  if (!employee || !userRoles || isLoadingEmployee || isLoadingRoles) {
    return null;
  }

  const role = getEmployeePageRole(userRoles);

  return (
    <Stack bg="zorroWhite.0" p="lg" mb="lg" style={{ borderRadius: 8 }}>
      <Group align="start">
        <UserAvatar
          firstName={employee.firstName}
          lastName={employee.lastName}
          color="zorroGreen.0"
          size={44}
          textSize={16}
        />
        <Stack gap="0">
          <Text fw={600} size="sm">
            {getFullName(employee)}
          </Text>
          <Group gap="xs">
            <Text c="zorroGray.4" size="sm">
              {employee.shortId}
            </Text>
            <CopyToClipboard
              text={employee.shortId}
              onCopy={() =>
                showSuccessNotification({
                  message: 'Zorro ID copied to clipboard',
                })
              }
            >
              <IconCopy size={16} cursor="pointer" color={brand.zorroGray400} />
            </CopyToClipboard>
          </Group>
        </Stack>
        <Box ml="auto">
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <ActionIcon variant="transparent">
                <ThreeDotsIcon />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                onClick={async () => {
                  await sendAccountActivationEmails([
                    {
                      userId: employee.userId,
                      employerId: employee.employerId,
                    },
                  ]);
                }}
              >
                Send account invitation
              </Menu.Item>
              <Menu.Item
                onClick={() =>
                  createImpersonationSession(
                    employeeId,
                    ZORRO_UI_DYNAMIC_ROUTES.MY_COVERAGE()
                  )
                }
              >
                Log in as
              </Menu.Item>
              <Menu.Item
                onClick={() => setAction(EmployeePageAction.EDIT_COMPANY_EMAIL)}
              >
                Edit company email
              </Menu.Item>
              {isZorroOperations && (
                <Menu.Item onClick={() => resetPassword(employee.userId)}>
                  Edit account password
                </Menu.Item>
              )}
              <Menu.Item
                onClick={() => setAction(EmployeePageAction.DELETE_EMPLOYEE)}
              >
                Delete employee
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Box>
      </Group>
      <Divider w="100%" />
      <Stack gap="xs">
        <Group justify="space-between">
          <Text c="zorroGray.4" size="sm">
            Role
          </Text>
          <Group gap="xs">
            <Text size="sm">{rolesLabelConfig[role]}</Text>
            <ActionIcon
              variant="transparent"
              onClick={() => setAction(EmployeePageAction.EDIT_EMPLOYEE_ROLE)}
            >
              <Icon icon={IconPencil} />
            </ActionIcon>
          </Group>
        </Group>
        <Group justify="space-between">
          <Text c="zorroGray.4" size="sm">
            Employee ID
          </Text>
          <Group gap="xs">
            <Text size="sm">{employee.idFromEmployer || '-'}</Text>
            <ActionIcon
              variant="transparent"
              onClick={() =>
                setAction(EmployeePageAction.EDIT_ID_FROM_EMPLOYER)
              }
            >
              <Icon icon={IconPencil} />
            </ActionIcon>
          </Group>
        </Group>
      </Stack>
      <EligibilityStatusBadge setAction={setAction} employeeId={employeeId} />
    </Stack>
  );
};
