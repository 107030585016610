import { ActionIcon, ActionIconProps, ActionIconVariant } from '@mantine/core';
import { HTMLAttributes, forwardRef } from 'react';

import classNames from './ActionIcon.module.scss';

export type IActionIconProps = Omit<ActionIconProps, 'variant'> &
  HTMLAttributes<HTMLButtonElement> & {
    grow?: boolean;
    variant?: ActionIconVariant | 'circle';
  };

export const ZorroActionIcon = forwardRef<HTMLButtonElement, IActionIconProps>(
  ({ grow, disabled = false, ...props }, ref) => {
    return (
      <ActionIcon
        ref={ref}
        classNames={classNames}
        data-disabled={disabled}
        data-grow={grow}
        {...props}
      />
    );
  }
);

ZorroActionIcon.displayName = 'ZorroActionIcon';
