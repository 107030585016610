import { YesNoNotSure } from '@zorro/clients';
import { AgentIcon, EmployeeIcon } from '@zorro/shared/assets';
import { VALIDATION_MESSAGES } from '@zorro/shared/utils';
import { Box, Icon, brand } from '@zorro/zorro-ui-design';
import { $enum } from 'ts-enum-util';
import * as yup from 'yup';

import { AgentOrEmployee } from './AgentOrEmployeeInput';

export const paymentStatusSchema = yup.object({
  initialPremiumHandler: yup
    .mixed<AgentOrEmployee>()
    .oneOf(
      Object.values(AgentOrEmployee),
      VALIDATION_MESSAGES.paymentHandlerRequired
    )
    .typeError(VALIDATION_MESSAGES.paymentHandlerRequired)
    .required(VALIDATION_MESSAGES.paymentHandlerRequired),
  initialPremiumCompleted: yup
    .mixed<YesNoNotSure>()
    .oneOf(
      Object.values(YesNoNotSure),
      VALIDATION_MESSAGES.wasPaymentCompletedRequired
    )
    .typeError(VALIDATION_MESSAGES.wasPaymentCompletedRequired)
    .required(VALIDATION_MESSAGES.wasPaymentCompletedRequired),
  autoPaySetter: yup
    .mixed<AgentOrEmployee>()
    .oneOf(
      Object.values(AgentOrEmployee),
      VALIDATION_MESSAGES.autoPaySetterRequired
    )
    .typeError(VALIDATION_MESSAGES.autoPaySetterRequired)
    .required(VALIDATION_MESSAGES.autoPaySetterRequired),
  autoPayCompleted: yup
    .mixed<YesNoNotSure>()
    .oneOf(Object.values(YesNoNotSure), VALIDATION_MESSAGES.hasAutoPayBeenSetUp)
    .typeError(VALIDATION_MESSAGES.hasAutoPayBeenSetUp)
    .required(VALIDATION_MESSAGES.hasAutoPayBeenSetUp),
});

export type PaymentStatusFormFields = yup.InferType<typeof paymentStatusSchema>;

export const getPaymentIcon = (
  byEmployee: boolean,
  completed: YesNoNotSure
) => {
  const backgroundColor = $enum.mapValue(completed).with({
    [YesNoNotSure.YES]: brand.zorroGreen300,
    [YesNoNotSure.NO]: brand.zorroFire300,
    [YesNoNotSure.NOT_SURE]: brand.zorroYolk400,
  });
  return (
    <Box
      bg={backgroundColor}
      w="2rem"
      h="2rem"
      style={(theme) => ({
        borderRadius: theme.spacing.xxs,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      })}
    >
      <Icon icon={byEmployee ? EmployeeIcon : AgentIcon} />
    </Box>
  );
};
