import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';

import { useAnalytics } from '../Analytics';
import {
  DependentsFormInputs,
  InsuredDependentFormFields,
} from './DependentsFormInputs';
import { EmployeeFormInputs } from './EmployeeFormInputs';
import { getDefaultInsuredFormFields } from './InsuredFormUtils';
import { SpouseFormInputs } from './SpouseFormInputs';
import { useInsuredForm } from './useInsuredForm';

type Props = {
  insuredForm: ReturnType<typeof useInsuredForm>;
  employerId: string;
  isFinalizationMode: boolean;
  isEmployeeMode: boolean;
};

export const InsuredFormInputs = ({
  insuredForm,
  employerId,
  isFinalizationMode,
  isEmployeeMode,
}: Props) => {
  const {
    employeeForm,
    spouseForm,
    dependentsForm,
    handleAddressValidation,
    hasAnyDependents,
    insured,
    isSpouseActive,
    setIsSpouseActive,
    targetEnrollmentDate,
  } = insuredForm;
  const { sendEvent } = useAnalytics();

  const employeeAddress = employeeForm.watch('residentialAddress');
  const { append, remove } = useFieldArray({
    control: dependentsForm.control,
    name: 'dependents',
  });

  useEffect(() => {
    setIsSpouseActive(Boolean(insured?.spouse));
  }, [setIsSpouseActive, insured]);

  return (
    <>
      <EmployeeFormInputs
        employeeForm={employeeForm}
        targetEnrollmentDate={targetEnrollmentDate}
        isFinalizationMode={isFinalizationMode}
        isEmployeeMode={isEmployeeMode}
        employerId={employerId}
      />
      <SpouseFormInputs
        spouseForm={spouseForm}
        setIsSpouseActive={(isActive) => {
          employeeForm.trigger();
          handleAddressValidation();
          if (hasAnyDependents) {
            dependentsForm.trigger();
          }
          setIsSpouseActive(isActive);
        }}
        isSpouseActive={isSpouseActive}
        isEmployeeMode={isEmployeeMode}
        isFinalizationMode={isFinalizationMode}
        targetEnrollmentDate={targetEnrollmentDate}
      />
      <DependentsFormInputs
        dependentsForm={dependentsForm}
        onDeleteDependent={(index) => {
          remove(index);
          sendEvent('remove_dependent');
        }}
        onAppendDependent={() => {
          employeeForm.trigger();
          handleAddressValidation();
          if (hasAnyDependents) {
            dependentsForm.trigger();
          }
          if (isSpouseActive) {
            spouseForm.trigger();
          }
          append(
            getDefaultInsuredFormFields(
              employeeAddress
            ) as InsuredDependentFormFields
          );
          sendEvent('add_dependent');
        }}
        isEmployeeMode={isEmployeeMode}
        isFinalizationMode={isFinalizationMode}
        targetEnrollmentDate={targetEnrollmentDate}
      />
    </>
  );
};
