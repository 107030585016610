import { Drawer, DrawerContentProps, DrawerProps } from '@mantine/core';
import { DRAWER_CONTENT_ZINDEX, DRAWER_OVERLAY_ZINDEX } from '@zorro/types';
import { ReactElement } from 'react';

import { ZorroGroup } from '../Group';
import { ZorroTitle } from '../Title';
import classNames from './DrawerCloseButton.module.scss';

export type IDrawerProps = DrawerProps & {
  children?: ReactElement;
  contentProps?: DrawerContentProps;
};

export function ZorroDrawer({
  opened,
  onClose,
  title,
  children,
  contentProps,
  withCloseButton = true,
  position = 'right',
  ...props
}: IDrawerProps) {
  return (
    <Drawer.Root
      position={position}
      onClose={onClose}
      opened={opened}
      size="xl"
      {...props}
    >
      <Drawer.Content
        style={{ boxShadow: 'none', zIndex: DRAWER_CONTENT_ZINDEX }}
        p="40px"
        {...contentProps}
      >
        <Drawer.Title p="0">
          <ZorroGroup justify="space-between" w="100%">
            <ZorroTitle fs="1.5rem" fw={600}>
              {title}
            </ZorroTitle>
            {withCloseButton && (
              <Drawer.CloseButton
                style={{ borderRadius: '2px' }}
                classNames={classNames}
                size="xl"
              />
            )}
          </ZorroGroup>
        </Drawer.Title>
        <Drawer.Body p="0" mt="lg">
          {children}
        </Drawer.Body>
      </Drawer.Content>
      <Drawer.Overlay zIndex={DRAWER_OVERLAY_ZINDEX} />
    </Drawer.Root>
  );
}
