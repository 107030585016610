import { WomanWritingOnTablet } from '@zorro/shared/assets';
import {
  getFullName,
  useDynamicMurrietaRouter,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import { Button, Drawer, Flex, Title } from '@zorro/zorro-ui-design';
import { useState } from 'react';

import { AddEnrollmentRecordForm } from '../EmployeeActions';

type Props = {
  employerId: string;
  employeeId: string;
};

export const NoEnrollments = ({ employerId, employeeId }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { navigateToRoute } = useDynamicMurrietaRouter();
  const { isEmployerAdmin } = useRoles();
  const { data: employee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });

  return (
    <>
      <Flex
        mt="4rem"
        justify="center"
        align="center"
        direction="column"
        gap="2rem"
      >
        <WomanWritingOnTablet grow />
        {employee && (
          <Title fw={600}>
            {getFullName(employee)} doesn't have any enrollments yet
          </Title>
        )}
        {!isEmployerAdmin && (
          <Button size="md" fw={400} onClick={() => setIsModalOpen(true)}>
            Add a new enrollment
          </Button>
        )}
      </Flex>

      {!isEmployerAdmin && employee && (
        <Drawer
          opened={isModalOpen}
          title="Add enrollment record"
          onClose={() => setIsModalOpen(false)}
        >
          <AddEnrollmentRecordForm
            employerId={employerId}
            onSuccess={(onboardingPeriodId) => {
              setIsModalOpen(false);
              onboardingPeriodId
                ? navigateToRoute('EMPLOYEE_ENROLLMENT_TAB', [
                    { employerId, employeeId, onboardingPeriodId },
                  ])
                : navigateToRoute('EMPLOYEE_PAGE', [
                    { employerId, employeeId },
                  ]);
            }}
            selectedEmployees={[
              {
                id: employeeId,
                firstName: employee.firstName,
                lastName: employee.lastName,
              },
            ]}
          />
        </Drawer>
      )}
    </>
  );
};
