import { yupResolver } from '@hookform/resolvers/yup';
import { EmployeeDto, OnboardingType } from '@zorro/clients';
import { formatDateISO } from '@zorro/shared/formatters';
import {
  UNEXPECTED_ERROR_MESSAGE,
  showErrorNotification,
  useForm,
  useMonolithQuery,
} from '@zorro/shared/utils';

import { useBatchCallEndpoint } from '../../hooks';
import { ErrorsTable } from '../Errors/ErrorsTable';
import { ChangeEnrollmentForm } from './ChangeEnrollmentForm';
import {
  ChangeEnrollmentsFormFields,
  FullChangeEnrollmentSchema,
  getCurrentlyEffectiveYear,
  getLatestPlanYear,
  getSuggestedElectionWindow,
  getSuggestedExpectedStartOfCoverage,
} from './changeEnrollment.utils';

export interface AddEnrollmentRecordFormProps {
  employerId: EmployeeDto['employerId'];
  selectedEmployees: {
    id: string;
    firstName: string;
    lastName: string;
  }[];
  onSuccess?: (onboardingPeriodId?: string) => void;
}

export const AddEnrollmentRecordForm = ({
  employerId,
  selectedEmployees,
  onSuccess,
}: AddEnrollmentRecordFormProps) => {
  const isSingleEmployee = selectedEmployees.length === 1;
  const singleEmployee = selectedEmployees[0];

  const { data: allOpenEnrollmentPeriods = [] } = useMonolithQuery({
    method: 'openEnrollmentPeriodsControllerFindAllForEmployer',
    params: [employerId],
  });

  const { data: associatedOnboardingPeriodsSingleEmployee } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindMany',
    params: [singleEmployee?.id],
    enabled: isSingleEmployee,
  });

  const { data: singleEmployeeDto } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [singleEmployee?.id],
    enabled: isSingleEmployee,
  });

  const {
    executeBatchCall: createEnrollments,
    errors: createEnrollmentsErrors,
  } = useBatchCallEndpoint({
    singularItemName: 'enrollment record',
    action: 'created',
    methodName: 'onboardingPeriodsControllerCreate',
  });

  const defaultPlanYear =
    getCurrentlyEffectiveYear(allOpenEnrollmentPeriods) ||
    getLatestPlanYear(allOpenEnrollmentPeriods) ||
    allOpenEnrollmentPeriods[0];

  const form = useForm<ChangeEnrollmentsFormFields>({
    mode: 'all',
    resolver: yupResolver(FullChangeEnrollmentSchema),
    values: {
      planYear: defaultPlanYear?.id,
      enrollmentType: OnboardingType.SPECIAL,
      electionWindow: getSuggestedElectionWindow(
        defaultPlanYear,
        OnboardingType.SPECIAL
      ),
      expectedStartOfCoverage: getSuggestedExpectedStartOfCoverage(
        defaultPlanYear,
        OnboardingType.SPECIAL,
        getSuggestedElectionWindow(defaultPlanYear, OnboardingType.SPECIAL),
        singleEmployeeDto
      ),
    },
  });

  const submitAddEnrollment = async (data: ChangeEnrollmentsFormFields) => {
    const {
      planYear: enrollmentPeriodId,
      enrollmentType,
      electionWindow,
      expectedStartOfCoverage,
    } = data;
    const [electionFrom, electionUntil] = electionWindow || [];

    if (!electionFrom || !electionUntil || !expectedStartOfCoverage) {
      showErrorNotification({ message: UNEXPECTED_ERROR_MESSAGE });
      return;
    }

    const enrollmentData = {
      enrollmentPeriodId,
      isSpecialEnrollment: enrollmentType === OnboardingType.SPECIAL,
      onboardingFrom: formatDateISO(electionFrom),
      onboardingUntil: formatDateISO(electionUntil),
      expectedStartOfCoverage: formatDateISO(expectedStartOfCoverage),
    };

    await createEnrollments(
      selectedEmployees.map((employee) => ({
        params: [
          {
            employeeId: employee.id,
            ...enrollmentData,
          },
        ],
        key: `${employee.firstName} ${employee.lastName}`,
      })),
      {
        onSuccess,
        shouldAvoidPageReload: true,
      }
    );
  };

  return (
    <>
      <ChangeEnrollmentForm
        form={form}
        onSubmit={submitAddEnrollment}
        allOpenEnrollmentPeriods={allOpenEnrollmentPeriods}
        singleEmployeeDto={singleEmployeeDto}
        associatedOtherOnboardingPeriodsSingleEmployee={
          associatedOnboardingPeriodsSingleEmployee
        }
      />

      <ErrorsTable
        tableTitle="Employee name"
        errors={createEnrollmentsErrors}
        isBulk={selectedEmployees?.length > 1}
      />
    </>
  );
};
