import { EmployeeDto, OnboardingPeriodDto } from '@zorro/clients';
import { useMonolithQuery } from '@zorro/shared/utils';
import { Card, Group, Stack, Text, Title } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

import { PAYMENT_SECTION_WIDTH } from '../paymentsDesign.consts';
import {
  autoPayConfig,
  initialPremiumPaymentConfig,
} from './paymentStatus.consts';

const defaultTextAndIcon = {
  label: '-',
  icon: null,
};

type EmployeePaymentStatusProps = {
  employeeId: EmployeeDto['id'];
  onboardingPeriodId: OnboardingPeriodDto['id'];
  children?: ReactNode;
};

export const EmployeePaymentStatus = ({
  employeeId,
  onboardingPeriodId,
  children,
}: EmployeePaymentStatusProps) => {
  const { isLoading, data } = useMonolithQuery({
    method: 'paymentsControllerGetPaymentMethod',
    params: [employeeId, onboardingPeriodId],
  });

  if (isLoading || !data) {
    return null;
  }

  const { initialPremiumPaymentStatus, autoPayStatus } = data;

  const { label: premiumPaymentLabel, icon: premiumPaymentIcon } =
    initialPremiumPaymentStatus
      ? initialPremiumPaymentConfig[initialPremiumPaymentStatus]
      : defaultTextAndIcon;

  const { label: autoPayLabel, icon: autoPayIcon } = autoPayStatus
    ? autoPayConfig[autoPayStatus]
    : defaultTextAndIcon;

  return (
    <Card maw={PAYMENT_SECTION_WIDTH}>
      <Group gap="xl" grow justify="space-between">
        <Stack>
          <Title order={3} size="sm">
            Initial premium payment
          </Title>
          <Group gap="xs">
            {premiumPaymentIcon}
            <Text size="sm">{premiumPaymentLabel}</Text>
          </Group>
        </Stack>
        <Stack>
          <Title order={3} size="sm">
            Auto-Pay
          </Title>
          <Group gap="xs">
            {autoPayIcon}
            <Text size="sm">{autoPayLabel}</Text>
          </Group>
        </Stack>
      </Group>
      {children}
    </Card>
  );
};
