import { ScrollArea } from '@mantine/core';
import { environmentName, isDevelopmentEnvironment } from '@zorro/environment';
import { showSuccessNotification, useImpersonation } from '@zorro/shared/utils';
import { Roles } from '@zorro/types';
import {
  Button,
  Card,
  Code,
  Drawer,
  Group,
  Stack,
  Text,
  Title,
  useDisclosure,
  useHotkeys,
} from '@zorro/zorro-ui-design';
import CopyToClipboard from 'react-copy-to-clipboard';

import {
  clearStorage,
  getFullStorySessionURL,
  isDebugModeEnabled,
  toggleDebugMode,
} from '../Analytics/analytics.utils';

const SupportInformation = () => {
  const { user, employee, authUser, authEmployee, isImpersonated } =
    useImpersonation();

  const [opened, { close, toggle }] = useDisclosure();

  useHotkeys([['mod+shift+P', toggle, { preventDefault: true }]]);

  if (typeof window === 'undefined') {
    return null;
  }

  const isAuthUserOmni = authUser?.roles.some(
    (role) => role.key === Roles.OMNIPOTENT_ADMIN
  );

  const isShowDevArea = isDevelopmentEnvironment() || isAuthUserOmni;

  const supportInformation = {
    environment: environmentName(),
    pageURL: window.location.href,
    sessionURL: getFullStorySessionURL(),
    userDetails: {
      isImpersonated,
      user,
      employee,
      authUser,
      authEmployee,
    },
    isAnalyticsDebugMode: isDebugModeEnabled(),
  };

  const stringySupportInformation = JSON.stringify(supportInformation, null, 2);

  return (
    <Drawer opened={opened} title="Support Information" onClose={close}>
      <Stack>
        <Card>
          <Stack gap="xs">
            <Title order={3}>General Information</Title>
            <Group gap="xs">
              <Text>Environment:</Text>
              <Code>{supportInformation.environment}</Code>
            </Group>
            <Group gap="xs">
              <Text>Page URL:</Text>
              <Code>{supportInformation.pageURL}</Code>
            </Group>
            <Group gap="xs">
              <Text>Session URL:</Text>
              <Code>{supportInformation.sessionURL}</Code>
            </Group>
          </Stack>
        </Card>
        <Card>
          <Stack gap="xs">
            <Title order={3}>User Details</Title>
            <Code block mah="15rem" className="fs-mask">
              <ScrollArea.Autosize>
                {JSON.stringify(supportInformation.userDetails, null, 2)}
              </ScrollArea.Autosize>
            </Code>
          </Stack>
        </Card>
        {isShowDevArea && (
          <Card>
            <Stack gap="xs">
              <Title order={3}>Dev Area</Title>
              <Group justify="space-between">
                <Group gap="xs">
                  <Text>Analytics Debug Mode:</Text>
                  <Code>
                    {supportInformation.isAnalyticsDebugMode ? 'Yes' : 'No'}
                  </Code>
                </Group>
                <Group gap="xs">
                  <Button size="xs" variant="light" onClick={toggleDebugMode}>
                    Toggle Debug Mode
                  </Button>
                  <Button size="xs" variant="light" onClick={clearStorage}>
                    Clear Storage
                  </Button>
                </Group>
              </Group>
            </Stack>
          </Card>
        )}

        <Group>
          <CopyToClipboard
            text={stringySupportInformation}
            onCopy={() =>
              showSuccessNotification({
                message: 'Copied support information to clipboard',
              })
            }
          >
            <Button size="lg">Copy support information to clipboard</Button>
          </CopyToClipboard>
        </Group>
      </Stack>
    </Drawer>
  );
};

export { SupportInformation };
