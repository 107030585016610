import { Group, Icon, Menu, Text, brand } from '@zorro/zorro-ui-design';
import { ElementType, ReactNode } from 'react';

type ActionsSubMenuProps = {
  title: string;
  icon: ElementType;
  children: ReactNode;
};

const sectionIconSize = '1.25rem';

const ActionsSubMenu = ({ title, icon, children }: ActionsSubMenuProps) => {
  return (
    <>
      <Menu.Label pb="xs">
        <Group gap="xs" justify="space-between">
          <Group gap="xs">
            <Icon
              icon={icon}
              size={sectionIconSize}
              color={brand.zorroGray800}
            />
            <Text size="sm" fw={500}>
              {title}
            </Text>
          </Group>
        </Group>
      </Menu.Label>
      {children}
    </>
  );
};

export { ActionsSubMenu };
