import { IconPencil } from '@tabler/icons-react';
import { EmployeeDto, OnboardingPeriodDto } from '@zorro/clients';
import { Button, Drawer, Icon, brand } from '@zorro/zorro-ui-design';
import { useState } from 'react';

import { FormFooter } from '../../FormFooter/FormFooter';
import { PaymentStatusForm } from './PaymentStatusForm';
import { usePaymentStatusForm } from './usePaymentStatusForm';

type ManagePaymentStatusProps = {
  employeeId: EmployeeDto['id'];
  onboardingPeriodId: OnboardingPeriodDto['id'];
};

const ManagePaymentStatus = ({
  employeeId,
  onboardingPeriodId,
}: ManagePaymentStatusProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isLoading, paymentStatusForm, onSubmit } = usePaymentStatusForm(
    employeeId,
    onboardingPeriodId
  );

  if (isLoading) {
    return null;
  }

  const handleSubmit = async () => {
    if (await onSubmit()) {
      setIsModalOpen(false);
    }
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        variant="transparent"
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
        }}
        size="md"
      >
        <Icon icon={IconPencil} color={brand.zorroGray800} />
      </Button>

      <Drawer
        onClose={() => setIsModalOpen(false)}
        title="Update Payment Status"
        position="right"
        opened={isModalOpen}
      >
        <>
          <PaymentStatusForm paymentStatusForm={paymentStatusForm} />
          <FormFooter
            primaryLabel="Save"
            primaryButtonProps={{
              onClick: handleSubmit,
              disabled: !paymentStatusForm.formState.isValid,
            }}
            secondaryLabel="Cancel"
            secondaryButtonProps={{ onClick: () => setIsModalOpen(false) }}
          />
        </>
      </Drawer>
    </>
  );
};

export { ManagePaymentStatus };
