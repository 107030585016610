import { IconDots } from '@tabler/icons-react';
import { EnrollmentStatus } from '@zorro/clients';
import { useDynamicMurrietaRouter, useRoles } from '@zorro/shared/utils';
import { ActionIcon, Icon, Menu, Text } from '@zorro/zorro-ui-design';
import { $enum } from 'ts-enum-util';

import { useMonolithMutation } from '../hooks/useMonolithMutation';

export enum EnrollmentTabAction {
  WAIVE_COVERAGE = 'WAIVE_COVERAGE',
  RESET_ELECTION = 'RESET_ELECTION',
  UNDO_ENROLLMENT_CONFIRMATION = 'UNDO_ENROLLMENT_CONFIRMATION',
  UNDO_APPLICATION = 'UNDO_APPLICATION',
  CHANGE_ELECTION_WINDOW = 'CHANGE_ELECTION_WINDOW',
}

type EnrollmentTabMenuActionProps = {
  label: string;
  onClick: () => void;
};

type GetMenuActions = (props: {
  setEnrollmentTabAction: (action: EnrollmentTabAction) => void;
  onboardingPeriodId: string;
  employerId: string;
  employeeId: string;
}) => EnrollmentTabMenuActionProps[];

/* eslint-disable sonarjs/no-duplicate-string */

type Props = {
  setEnrollmentTabAction: (action: EnrollmentTabAction) => void;
  enrollmentStatus: EnrollmentStatus;
  onboardingPeriodId: string;
  employerId: string;
  employeeId: string;
};

export const EnrollmentTabMenuActions = ({
  enrollmentStatus,
  ...props
}: Props) => {
  const { employerId, employeeId, onboardingPeriodId } = props;

  const { navigateToRoute } = useDynamicMurrietaRouter();
  const { isEmployerAdmin } = useRoles();

  const { mutate: sendWaiverConfirmationEmail } = useMonolithMutation({
    method: 'onboardingPeriodsControllerSendWaiverConfirmation',
    successMessage: 'Email sent',
  });

  const { mutate: sendElectionSummaryEmail } = useMonolithMutation({
    method: 'onboardingPeriodsControllerSendSummaryEmail',
    successMessage: 'Email sent',
  });

  const { mutate: sendElectionWindowLaunchEmail } = useMonolithMutation({
    method: 'onboardingPeriodsControllerSendElectionWindowLaunchEmail',
    successMessage: 'Email sent',
  });

  const handleElectionMenuActions: GetMenuActions = ({
    setEnrollmentTabAction,
  }) => {
    return isEmployerAdmin
      ? []
      : [
          {
            label: 'Change election window',
            onClick: () =>
              setEnrollmentTabAction(
                EnrollmentTabAction.CHANGE_ELECTION_WINDOW
              ),
          },
        ];
  };

  const handleApplicationSentMenuActions = () => {
    return isEmployerAdmin
      ? [
          {
            label: 'Send election summary email',
            onClick: () => sendElectionSummaryEmail([onboardingPeriodId]),
          },
        ]
      : [
          {
            label: 'Send election summary email',
            onClick: () => sendElectionSummaryEmail([onboardingPeriodId]),
          },
          {
            label: 'Change application details',
            onClick: () => {
              navigateToRoute('CARRIER_APPLICATION_ENROLLMENT_DETAILS', [
                {
                  employerId,
                  employeeId,
                  onboardingPeriodId,
                },
              ]);
            },
          },
          {
            label: 'Undo application',
            onClick: () =>
              props.setEnrollmentTabAction(
                EnrollmentTabAction.UNDO_APPLICATION
              ),
          },
          {
            label: 'Reset election',
            onClick: () =>
              props.setEnrollmentTabAction(EnrollmentTabAction.RESET_ELECTION),
          },
        ];
  };

  const handleWaiverMenuActions: GetMenuActions = ({
    setEnrollmentTabAction,
    employeeId,
  }) => [
    {
      label: 'Send waiver confirmation email',
      onClick: () => sendWaiverConfirmationEmail([employeeId]),
    },
    {
      label: 'Change waiver details',
      onClick: () => setEnrollmentTabAction(EnrollmentTabAction.WAIVE_COVERAGE),
    },
    {
      label: 'Reset election',
      onClick: () => setEnrollmentTabAction(EnrollmentTabAction.RESET_ELECTION),
    },
  ];

  const handleEnrollmentMenuActions: GetMenuActions = ({
    setEnrollmentTabAction,
    employerId,
    employeeId,
    onboardingPeriodId,
  }) =>
    isEmployerAdmin
      ? [
          {
            label: 'Send election summary email',
            onClick: () => sendElectionSummaryEmail([onboardingPeriodId]),
          },
        ]
      : [
          {
            label: 'Send election summary email',
            onClick: () => sendElectionSummaryEmail([onboardingPeriodId]),
          },
          {
            label: 'Change enrollment details',
            onClick: () => {
              navigateToRoute('ENROLLMENT_CONFIRMATION_ENROLLMENT_DETAILS', [
                {
                  employerId,
                  employeeId,
                  onboardingPeriodId,
                },
              ]);
            },
          },
          {
            label: 'Undo enrollment confirmation',
            onClick: () =>
              setEnrollmentTabAction(
                EnrollmentTabAction.UNDO_ENROLLMENT_CONFIRMATION
              ),
          },
          {
            label: 'Reset election',
            onClick: () =>
              setEnrollmentTabAction(EnrollmentTabAction.RESET_ELECTION),
          },
        ];

  const electionWindowLaunchAction: EnrollmentTabMenuActionProps = {
    label: 'Send election window launch email',
    onClick: () => sendElectionWindowLaunchEmail([onboardingPeriodId]),
  };

  const actions = $enum
    .mapValue(enrollmentStatus)
    .with<EnrollmentTabMenuActionProps[]>({
      [EnrollmentStatus.PENDING_ELECTION_WINDOW]: [
        ...handleElectionMenuActions(props),
        electionWindowLaunchAction,
      ],
      [EnrollmentStatus.ELECTION_ACTIVE]: handleElectionMenuActions(props),
      [EnrollmentStatus.ELECTION_ACTIVE_HAS_NOT_STARTED]: [
        ...handleElectionMenuActions(props),
        electionWindowLaunchAction,
      ],
      [EnrollmentStatus.ELECTION_ACTIVE_STARTED]: [
        ...handleElectionMenuActions(props),
        electionWindowLaunchAction,
      ],
      [EnrollmentStatus.DEADLINE_PASSED]: handleElectionMenuActions(props),
      [EnrollmentStatus.ELECTION_SUBMITTED]: [
        {
          label: 'Send election summary email',
          onClick: () => sendElectionSummaryEmail([onboardingPeriodId]),
        },
        {
          label: 'Change submission details',
          onClick: () => {
            navigateToRoute('MANUAL_SUBMISSION_HOUSEHOLD_INFORMATION', [
              {
                employerId,
                employeeId,
                onboardingPeriodId,
              },
            ]);
          },
        },
        {
          label: 'Reset election',
          onClick: () =>
            props.setEnrollmentTabAction(EnrollmentTabAction.RESET_ELECTION),
        },
      ],
      [EnrollmentStatus.WAIVED_ELECTION]: handleWaiverMenuActions(props),
      [EnrollmentStatus.WAIVED_COVERAGE]: handleWaiverMenuActions(props),
      [EnrollmentStatus.CARRIER_APPLICATION_SENT]:
        handleApplicationSentMenuActions(),
      [EnrollmentStatus.ENROLLMENT_CONFIRMED]:
        handleEnrollmentMenuActions(props),
      [EnrollmentStatus.COVERAGE_ENDED]: handleEnrollmentMenuActions(props),
      [EnrollmentStatus.ACTIVE_COVERAGE]: handleEnrollmentMenuActions(props),
      [EnrollmentStatus.NO_ENROLLMENTS]: [],
    });

  if (actions.length === 0) {
    return null;
  }

  return (
    <Menu width="18.75rem">
      <Menu.Target>
        <ActionIcon variant="transparent">
          <Icon icon={IconDots} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {actions.map(({ label, onClick }) => (
          <Menu.Item key={label} onClick={onClick}>
            <Text size="sm" fw="400">
              {label}
            </Text>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
