import { Box, Button, Flex, Modal, Space, Text } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

export type ConfirmationModalProps = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
  confirmButtonColor?: string;
  title: string;
  icon: ReactNode;
  children: ReactNode;
};

export const ConfirmationModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
  onCancel,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  confirmButtonColor,
  title,
  icon,
  children,
}: ConfirmationModalProps) => {
  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      title={
        <Flex align="center">
          {icon}
          <Text size="xl" ml="sm" fw={500}>
            {title}
          </Text>
        </Flex>
      }
    >
      <Box>
        {children}
        <Space h="md" />
        <Flex style={{ justifyContent: 'center' }}>
          <Button
            variant="secodary"
            onClick={() => {
              if (onCancel) {
                onCancel();
              }
              setIsOpen(false);
            }}
          >
            {cancelText}
          </Button>
          <Space w="sm" />
          <Button
            onClick={() => {
              if (onConfirm) {
                onConfirm();
              }
              setIsOpen(false);
            }}
            styles={{
              root: {
                backgroundColor: confirmButtonColor,
              },
            }}
          >
            {confirmText}
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};
