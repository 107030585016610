import { IconCircleX } from '@tabler/icons-react';
import {
  Box,
  Button,
  Center,
  Group,
  Modal,
  Space,
  Text,
  Title,
} from '@zorro/zorro-ui-design';

type Props = {
  isOpened: boolean;
  setIsOpened: (opened: boolean) => void;
  employerName: string;
};

export const CancelInviteModal = ({
  isOpened,
  setIsOpened,
  employerName,
}: Props) => {
  return (
    <Modal opened={isOpened} onClose={() => setIsOpened(false)} size="md">
      <Box>
        <Group gap="sm">
          <Center w={42} h={38} bg="zorroFire.3" style={{ borderRadius: 8 }}>
            <IconCircleX />
          </Center>
          <Title fw={600}>Cancel Invitation?</Title>
        </Group>
        <Text mt="lg" fw={500}>
          {`Are you sure you want to cancel the invitation to ${employerName}? They
          won't be able to use the link you shared`}
        </Text>
        <Space h="xl" />
        <Center mt="md">
          <Button
            variant="subtle"
            size="lg"
            c="zorroCoal.9"
            onClick={() => setIsOpened(false)}
          >
            Cancel
          </Button>
          <Button ml="xl" bg="zorroFire.7" size="lg">
            Cancel invitation
          </Button>
        </Center>
      </Box>
    </Modal>
  );
};
