import { AllowanceModelDto } from '@zorro/clients';

import { FileUploadDisplay } from '../FileUploadDisplay/FileUploadDisplay';
import { exportAllowanceModelToCsv } from '../PlanYearSetupForm/PlanYearSetupUtils';
import { UploadedFileBox } from '../UploadedFileBox';

type AllowanceModelInfoProps = {
  allowanceModel: AllowanceModelManagementProps['allowanceModel'];
};

function AllowanceModelInfo({ allowanceModel }: AllowanceModelInfoProps) {
  if (!allowanceModel) {
    return null;
  }

  if (allowanceModel instanceof File) {
    return <FileUploadDisplay fileName={allowanceModel.name} />;
  }

  return (
    <FileUploadDisplay
      fileName={`Existing ${allowanceModel.year} allowance model`}
      onClick={() => exportAllowanceModelToCsv(allowanceModel)}
    />
  );
}

type AllowanceModelManagementProps = {
  allowanceModel?: File | AllowanceModelDto;
  onClickDelete: () => void;
};

const AllowanceModelManagement = ({
  allowanceModel,
  onClickDelete,
}: AllowanceModelManagementProps) => {
  if (!allowanceModel) {
    return null;
  }

  return (
    <UploadedFileBox onClickDelete={onClickDelete}>
      <AllowanceModelInfo allowanceModel={allowanceModel} />
    </UploadedFileBox>
  );
};

export { AllowanceModelManagement };
