import { Container, ContainerProps } from '@mantine/core';

export type IContainerProps = ContainerProps;

const DEFAULT_PAGE_WIDTH = 700;

// appShell component already provides padding for the entire page
const DEFAULT_PADDING_HORIZONTAL = 0;

// subtract padding top which appShell already applies by default
const DEFAULT_MARGIN_TOP = 'xl';

export function ZorroContainer({
  size = DEFAULT_PAGE_WIDTH,
  px = DEFAULT_PADDING_HORIZONTAL,
  pb = 'xl',
  mt = DEFAULT_MARGIN_TOP,
  mb = 'xl',
  ...props
}: IContainerProps) {
  return <Container size={size} px={px} pb={pb} mt={mt} mb={mb} {...props} />;
}
