import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { Gender, InsuredSubtype } from '@zorro/clients';
import { DateUtilInstance } from '@zorro/shared/formatters';
import {
  VALIDATION_MESSAGES,
  getAdultDefaultDate,
  insuredMaxDateOfBirth,
  insuredMinDateOfBirth,
  validateOnlyNumbers,
} from '@zorro/shared/utils';
import { YesNo, insuredSubtypeLabelConfig } from '@zorro/types';
import {
  ActionIcon,
  AddressInput,
  Box,
  Button,
  Collapse,
  DateInput,
  FormErrorMessage,
  Grid,
  Group,
  Icon,
  InputWrapper,
  PasswordInput,
  Select,
  Space,
  TabButtonGroup,
  Tabs,
  Text,
  TextInput,
  Tooltip,
} from '@zorro/zorro-ui-design';
import { useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';

import { useAnalytics } from '../Analytics';
import { CheckmarkBoxWrapper } from './CheckmarkBoxWrapper';
import {
  INSURED_FORM_ADDRESS_TOOLTIP_LABEL,
  getDefaultInsuredFormFields,
  getInsuredBaseSchema,
} from './InsuredFormUtils';

const spouseRelationshipValues = Object.values([
  InsuredSubtype.SPOUSE,
  InsuredSubtype.DOMESTIC_PARTNER,
]);

const insuredTypeOptions = spouseRelationshipValues.map((status) => ({
  label: insuredSubtypeLabelConfig[status],
  value: status,
}));

export const getSpouseInsuredSchema = (isFinalizationMode: boolean) =>
  getInsuredBaseSchema(isFinalizationMode).concat(
    yup.object({
      ...(isFinalizationMode
        ? {
            subtype: yup
              .mixed<InsuredSubtype>()
              .required(VALIDATION_MESSAGES.relationshipRequired)
              .oneOf(
                spouseRelationshipValues,
                VALIDATION_MESSAGES.relationshipRequired
              ),
          }
        : {
            subtype: yup
              .mixed<InsuredSubtype>()
              .oneOf(
                spouseRelationshipValues,
                VALIDATION_MESSAGES.relationshipRequired
              )
              .nullable(),
          }),
    })
  );

export type InsuredSpouseFormFields = yup.InferType<
  ReturnType<typeof getSpouseInsuredSchema>
>;

type Props = {
  setIsSpouseActive: (value: boolean) => void;
  isSpouseActive: boolean;
  spouseForm: UseFormReturn<InsuredSpouseFormFields>;
  targetEnrollmentDate: DateUtilInstance;
  isFinalizationMode: boolean;
  isEmployeeMode: boolean;
};

export const SpouseFormInputs = ({
  isEmployeeMode,
  isFinalizationMode,
  setIsSpouseActive,
  isSpouseActive,
  spouseForm,
  targetEnrollmentDate,
}: Props) => {
  const { formState, control, trigger, watch, reset } = spouseForm;
  const { isValid, errors } = formState;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { sendEvent } = useAnalytics();

  useEffect(() => {
    if (!isValid && isSpouseActive) {
      setIsOpen(true);
    }
  }, [isValid, isSpouseActive, setIsOpen]);

  const fullName = `${watch('firstName') || ''} ${
    watch('lastName') || ''
  }`.trim();
  const isFemale = watch('gender') === Gender.FEMALE;
  const subtypeLabel =
    watch('subtype') === InsuredSubtype.DOMESTIC_PARTNER
      ? 'Domestic partner'
      : 'Spouse';

  const handleArrowClick = async () => {
    const result = await trigger();
    if (!isOpen || result) {
      setIsOpen((prev) => !prev);
    }
  };

  const handleCheckmarkClick = () => {
    if (!isSpouseActive) {
      setIsSpouseActive(true);
      setIsOpen(true);
      sendEvent('add_spouse');
    }
  };

  const handleDeleteSpouse = () => {
    setIsOpen(false);
    reset(getDefaultInsuredFormFields(undefined));
    setIsSpouseActive(false);
    sendEvent('remove_spouse');
  };

  return (
    <CheckmarkBoxWrapper
      hasErrors={isSpouseActive && Object.keys(errors).length > 0}
      isFilledOut={isSpouseActive && isValid}
      handleKeyDown={handleCheckmarkClick}
      handleClick={handleCheckmarkClick}
      tabIndex={isSpouseActive ? -1 : 0}
      style={{ cursor: isSpouseActive ? 'default' : 'pointer' }}
      py={isSpouseActive && isOpen ? '2rem' : '1.125rem'}
      px={{ base: '1.5rem', sm: '3rem' }}
      isOpen={isOpen}
      opacity={1}
      w="100%"
    >
      {isSpouseActive ? (
        <>
          <Group justify="space-between">
            <Text fw="600">
              {!fullName || isOpen
                ? 'Spouse / partner'
                : `${subtypeLabel} (${fullName})`}
            </Text>

            <ActionIcon
              variant="transparent"
              onClick={handleArrowClick}
              onKeyDown={(event) => {
                if (event.code === 'Space' || event.code === 'Enter') {
                  handleArrowClick();
                }
              }}
            >
              {isOpen ? (
                <Icon icon={IconChevronUp} aria-label="Collapse spouse form" />
              ) : (
                <Icon icon={IconChevronDown} aria-label="Expand spouse form" />
              )}
            </ActionIcon>
          </Group>

          <Collapse in={isOpen}>
            <Space h="xl" />
            <Grid>
              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field: { ...rest } }) => (
                    <TextInput
                      {...rest}
                      label="First name"
                      placeholder="First name"
                      required
                      masked
                    />
                  )}
                />

                <FormErrorMessage errors={errors} fieldName="firstName" />
              </Grid.Col>

              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name="lastName"
                  render={({ field: { ...rest } }) => (
                    <TextInput
                      {...rest}
                      label="Last name"
                      placeholder="Last name"
                      required
                      masked
                    />
                  )}
                />

                <FormErrorMessage errors={errors} fieldName="lastName" />
              </Grid.Col>

              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name="subtype"
                  render={({ field: { ...rest } }) => (
                    <Select
                      {...rest}
                      label="Relationship"
                      placeholder="Select relationship"
                      data={insuredTypeOptions}
                      required={isFinalizationMode}
                    />
                  )}
                />

                <FormErrorMessage errors={errors} fieldName="subtype" />
              </Grid.Col>

              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name="dateOfBirth"
                  render={({ field: { ...rest } }) => (
                    <DateInput
                      {...rest}
                      label="Date of birth"
                      defaultLevel="decade"
                      defaultDate={getAdultDefaultDate()}
                      minDate={insuredMinDateOfBirth(
                        false,
                        targetEnrollmentDate
                      )}
                      maxDate={insuredMaxDateOfBirth(
                        false,
                        targetEnrollmentDate
                      )}
                      required
                      masked
                    />
                  )}
                />

                <FormErrorMessage errors={errors} fieldName="dateOfBirth" />
              </Grid.Col>

              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name="gender"
                  render={({ field: { ...rest } }) => (
                    <InputWrapper label="Gender" required={isFinalizationMode}>
                      <TabButtonGroup {...rest}>
                        <Tabs.List grow>
                          <Tabs.Tab value={Gender.MALE}>Male</Tabs.Tab>
                          <Tabs.Tab value={Gender.FEMALE}>Female</Tabs.Tab>
                        </Tabs.List>
                      </TabButtonGroup>
                    </InputWrapper>
                  )}
                />

                <FormErrorMessage errors={errors} fieldName="gender" />
              </Grid.Col>

              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name="isSmoker"
                  render={({ field: { ...rest } }) => (
                    <InputWrapper
                      label="Tobacco User"
                      required={isFinalizationMode}
                    >
                      <TabButtonGroup {...rest}>
                        <Tabs.List grow>
                          <Tabs.Tab value={YesNo.YES}>Yes</Tabs.Tab>
                          <Tabs.Tab value={YesNo.NO}>No</Tabs.Tab>
                        </Tabs.List>
                      </TabButtonGroup>
                    </InputWrapper>
                  )}
                />

                <FormErrorMessage errors={errors} fieldName="isSmoker" />
              </Grid.Col>

              {isFemale && (
                <Grid.Col span={{ sm: 6 }}>
                  <Controller
                    control={control}
                    name="isPregnant"
                    render={({ field: { ...rest } }) => (
                      <InputWrapper
                        label="Pregnant"
                        required={isFinalizationMode}
                      >
                        <TabButtonGroup {...rest}>
                          <Tabs.List grow>
                            <Tabs.Tab value={YesNo.YES}>Yes</Tabs.Tab>
                            <Tabs.Tab value={YesNo.NO}>No</Tabs.Tab>
                          </Tabs.List>
                        </TabButtonGroup>
                      </InputWrapper>
                    )}
                  />

                  <FormErrorMessage errors={errors} fieldName="isPregnant" />
                </Grid.Col>
              )}

              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name="ssn"
                  render={({ field: { value, ...rest } }) => (
                    <PasswordInput
                      {...rest}
                      label="SSN"
                      placeholder="XXXXXXXXX"
                      value={value || ''}
                      maxLength={9}
                      onKeyDown={validateOnlyNumbers}
                      required={isFinalizationMode}
                    />
                  )}
                />

                <FormErrorMessage errors={errors} fieldName="ssn" />
              </Grid.Col>

              <Grid.Col>
                <Controller
                  control={control}
                  name="residentialAddress"
                  render={({ field: { value, onBlur, onChange, ...rest } }) => (
                    <Tooltip
                      label={INSURED_FORM_ADDRESS_TOOLTIP_LABEL}
                      disabled={!isEmployeeMode}
                      position="top"
                      maw={400}
                    >
                      <Box pos="relative">
                        <AddressInput
                          {...rest}
                          onBlur={() => {
                            onChange(value);
                            onBlur();
                          }}
                          notifySubscribers={(newAddress) =>
                            onChange(newAddress)
                          }
                          onChange={onChange}
                          label="Residential address"
                          value={value || ''}
                          placeholder="Type residential address"
                          required={isFinalizationMode}
                          disabled={isEmployeeMode}
                          masked
                        />
                      </Box>
                    </Tooltip>
                  )}
                />

                <FormErrorMessage
                  errors={errors}
                  fieldName="residentialAddress"
                />
              </Grid.Col>

              <Grid.Col style={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant="subtle" p={1} onClick={handleDeleteSpouse}>
                  Delete
                </Button>
              </Grid.Col>
            </Grid>
          </Collapse>
        </>
      ) : (
        <Text ta="center" c="zorroIris.9">
          + Add spouse / domestic partner
        </Text>
      )}
    </CheckmarkBoxWrapper>
  );
};
