import { IconRepeat } from '@tabler/icons-react';
import { useMonolithQuery } from '@zorro/shared/utils';
import {
  Card,
  Drawer,
  Group,
  Modal,
  Stack,
  brand,
} from '@zorro/zorro-ui-design';
import { useState } from 'react';

import {
  ChangeElectionWindowForm,
  ResetElectionForm,
  WaiveCoverageForm,
} from '../EmployeeActions';
import { EnrollmentActivityLog } from '../EnrollmentActivityLog';
import { EnrollmentStatusBadge } from '../EnrollmentStatus';
import { FullPageLoader } from '../FullPageLoader';
import {
  ModalTitle,
  UndoApplicationForm,
  UndoEnrollmentConfirmationForm,
} from '../SimpleActionModal';
import { EnrollmentTabButtons } from './EnrollmentTabButtons';
import { EnrollmentTabContent } from './EnrollmentTabContent';
import { EnrollmentTabLabels } from './EnrollmentTabLabels';
import {
  EnrollmentTabAction,
  EnrollmentTabMenuActions,
} from './EnrollmentTabMenuActions';
import { EnrollmentTabTitle } from './EnrollmentTabTitle';

type Props = {
  employerId: string;
  employeeId: string;
  onboardingPeriodId: string;
};

export const EnrollmentTab = ({
  employerId,
  employeeId,
  onboardingPeriodId,
}: Props) => {
  const [enrollmentTabAction, setEnrollmentTabAction] =
    useState<EnrollmentTabAction>();
  const { data: employee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });
  const { data: onboardingPeriod } = useMonolithQuery({
    method: 'onboardingPeriodReportsControllerGenerateOnboardingPeriodReport',
    params: [onboardingPeriodId],
  });

  if (!onboardingPeriod || !employee) {
    return <FullPageLoader />;
  }

  const { enrollmentStatus } = onboardingPeriod;

  return (
    <Stack gap="1.5rem" maw={900}>
      <Card p="20px 40px 40px 40px" style={{ borderColor: brand.zorroGray200 }}>
        <Stack>
          <Group justify="space-between">
            <EnrollmentStatusBadge enrollmentStatus={enrollmentStatus} />
            <EnrollmentTabMenuActions
              setEnrollmentTabAction={setEnrollmentTabAction}
              onboardingPeriodId={onboardingPeriodId}
              enrollmentStatus={enrollmentStatus}
              employerId={employerId}
              employeeId={employeeId}
            />
          </Group>
          <EnrollmentTabTitle
            enrollmentStatus={enrollmentStatus}
            employeeId={employeeId}
          />
          <EnrollmentTabLabels
            majorMedicalBenefit={onboardingPeriod.majorMedicalBenefit}
            onboardingPeriod={onboardingPeriod}
            enrollmentStatus={enrollmentStatus}
          />
          <EnrollmentTabContent
            onboardingPeriodId={onboardingPeriodId}
            enrollmentStatus={enrollmentStatus}
            employerId={employerId}
            employee={employee}
          />
        </Stack>
      </Card>

      <EnrollmentTabButtons
        onWaiveCoverageClick={() =>
          setEnrollmentTabAction(EnrollmentTabAction.WAIVE_COVERAGE)
        }
        onboardingPeriodId={onboardingPeriodId}
        enrollmentStatus={enrollmentStatus}
        employerId={employerId}
        employeeId={employeeId}
      />

      <EnrollmentActivityLog
        userId={employee.userId}
        enrollmentActivityLogs={onboardingPeriod.enrollmentActivityLogs}
      />

      <Drawer
        opened={
          enrollmentTabAction === EnrollmentTabAction.CHANGE_ELECTION_WINDOW
        }
        title="Change election window"
        onClose={() => setEnrollmentTabAction(undefined)}
      >
        <ChangeElectionWindowForm
          planYearId={onboardingPeriod.enrollmentPeriodId}
          selectedEmployees={[
            {
              id: employeeId,
              onboardingPeriodId,
              firstName: employee.firstName,
              lastName: employee.lastName,
            },
          ]}
          onSuccess={() => setEnrollmentTabAction(undefined)}
        />
      </Drawer>
      <Drawer
        opened={enrollmentTabAction === EnrollmentTabAction.WAIVE_COVERAGE}
        title="Waive coverage"
        onClose={() => setEnrollmentTabAction(undefined)}
      >
        <WaiveCoverageForm
          selectedEmployees={[
            {
              id: employeeId,
              onboardingPeriodId,
              fullName: `${employee.firstName} ${employee.lastName}`,
            },
          ]}
          onSuccess={() => setEnrollmentTabAction(undefined)}
          onClose={() => setEnrollmentTabAction(undefined)}
        />
      </Drawer>
      <Modal
        onClose={() => setEnrollmentTabAction(undefined)}
        opened={enrollmentTabAction === EnrollmentTabAction.RESET_ELECTION}
        showSeparator={false}
        size="lg"
        title={
          <ModalTitle
            bgIcon={brand.zorroFire500}
            title="Reset election?"
            icon={<IconRepeat color={brand.zorroCoal900} />}
          />
        }
      >
        <ResetElectionForm
          selectedEmployees={[
            {
              onboardingPeriodId,
              firstName: employee.firstName,
              lastName: employee.lastName,
            },
          ]}
          onClose={() => setEnrollmentTabAction(undefined)}
          onSuccess={() => setEnrollmentTabAction(undefined)}
        />
      </Modal>

      {onboardingPeriod?.majorMedicalBenefit && (
        <Modal
          size="lg"
          opened={
            enrollmentTabAction ===
            EnrollmentTabAction.UNDO_ENROLLMENT_CONFIRMATION
          }
          onClose={() => setEnrollmentTabAction(undefined)}
          showSeparator={false}
          title={
            <ModalTitle
              title="Undo enrollment confirmation?"
              icon={<IconRepeat color={brand.zorroCoal900} />}
              bgIcon={brand.zorroPlum100}
            />
          }
        >
          <UndoEnrollmentConfirmationForm
            benefitEnrollmentId={onboardingPeriod.majorMedicalBenefit.id}
            closeModal={() => setEnrollmentTabAction(undefined)}
          />
        </Modal>
      )}

      {onboardingPeriod?.majorMedicalBenefit && (
        <Modal
          size="lg"
          onClose={() => setEnrollmentTabAction(undefined)}
          opened={enrollmentTabAction === EnrollmentTabAction.UNDO_APPLICATION}
          showSeparator={false}
          title={
            <ModalTitle
              title="Undo application?"
              icon={<IconRepeat color={brand.zorroCoal900} />}
              bgIcon={brand.zorroPlum100}
            />
          }
        >
          <UndoApplicationForm
            benefitEnrollmentId={onboardingPeriod.majorMedicalBenefit.id}
            closeModal={() => setEnrollmentTabAction(undefined)}
          />
        </Modal>
      )}
    </Stack>
  );
};
