import { BenefitStatus } from '@zorro/clients';
import { Space, Text } from '@zorro/zorro-ui-design';

import { useMonolithMutation } from '../hooks';
import { ModalFooter } from './ModalFooter';

interface UndoApplicationModalProps {
  benefitEnrollmentId: string;
  closeModal: () => void;
}

export const UndoApplicationForm = ({
  benefitEnrollmentId,
  closeModal,
}: UndoApplicationModalProps) => {
  const { mutate: changeBenefitEnrollmentStatus } = useMonolithMutation({
    method: 'benefitEnrollmentsControllerPatch',
  });

  const onSubmit = async () => {
    await changeBenefitEnrollmentStatus([
      benefitEnrollmentId,
      { status: BenefitStatus.SUBMITTED },
    ]);
    closeModal();
  };

  return (
    <>
      <Text size="lg">
        The enrollment will be set back to election submitted status
      </Text>

      <Space h="xl" />
      <ModalFooter
        submitText="Change status"
        onSubmit={onSubmit}
        onClose={closeModal}
        submitVariant="primary"
      />
    </>
  );
};
