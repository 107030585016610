import { TitleOrder } from '@mantine/core';
import { Group, ThemeIcon, Title } from '@zorro/zorro-ui-design';

type Props = {
  title: string;
  icon?: React.ReactNode;
  bgIcon?: string;
  titleOrder?: TitleOrder;
};

export const ModalTitle = ({ title, icon, bgIcon, titleOrder = 1 }: Props) => {
  return (
    <Group align="center" wrap="nowrap" gap="sm">
      {bgIcon ? (
        <ThemeIcon bg={bgIcon} pos="relative" radius="0.25rem" p={4} size={32}>
          {icon}
        </ThemeIcon>
      ) : (
        icon
      )}
      <Title order={titleOrder}>{title}</Title>
    </Group>
  );
};
