import { Card, Flex, Stack, Text } from '@zorro/zorro-ui-design';
import Image from 'next/image';

import { PAYMENT_METHOD_DIRECT_ACH_CONTAINER_WIDTH } from '../paymentsDesign.consts';

const PaymentMethodNotice = () => {
  return (
    <Card
      px="md"
      py="lg"
      style={{ width: PAYMENT_METHOD_DIRECT_ACH_CONTAINER_WIDTH }}
    >
      <Flex columnGap="xs" align="flex-start" wrap="nowrap">
        <Image
          src="icons/zorro-bulb.svg"
          alt="icon bulb"
          width="32"
          height="32"
        />
        <Stack>
          <Text size="sm">
            This payment method is set up for your healthcare premiums, funded
            by your employer. It covers both your allowance and any
            self-contributions. Please use it exclusively for premium payments
            to your carrier.
          </Text>
          <Text size="sm">
            Simplify your payments by setting up auto-pay. Simply log into your
            carrier&apos;s portal and input this payment info to automate your
            premiums.
          </Text>
        </Stack>
      </Flex>
    </Card>
  );
};

export { PaymentMethodNotice };
