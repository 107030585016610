import { IconDotsVertical } from '@tabler/icons-react';
import { ActionIcon, Menu } from '@zorro/zorro-ui-design';

export type EditSubMenuItem = {
  title: string;
  onClick: () => void;
  disabled?: boolean;
};

export type EditSubMenuProps = {
  items: EditSubMenuItem[];
};

export const EditSubMenu = ({ items }: EditSubMenuProps) => {
  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <ActionIcon variant="transparent">
          <IconDotsVertical />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        {items.map((item) => (
          <Menu.Item
            key={item.title}
            onClick={item.onClick}
            disabled={item.disabled}
          >
            {item.title}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
