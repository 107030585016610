import { MantineColor, Radio, RadioCardProps, StyleProp } from '@mantine/core';

import { Text } from '../../../index';
import { ZorroCenter } from '../../Center';
import { ZorroFlex } from '../../Flex';
import { ZorroGroup } from '../../Group';
import { ZorroRadioIndicator } from '../RadioIndicator/RadioIndicator';

export type IRadioCardProps = RadioCardProps & {
  iconColor?: StyleProp<MantineColor>;
  icon?: JSX.Element;
  label?: string;
};

export const ZorroRadioCard = ({
  icon,
  label,
  iconColor,
  ...props
}: IRadioCardProps) => {
  return (
    <Radio.Card radius="md" w="100%" h="3rem" pl="xs" pr="xs" {...props}>
      <ZorroGroup align="center" justify="space-between">
        <ZorroFlex align="center">
          {icon && (
            <ZorroCenter
              bg={iconColor}
              w="2rem"
              h="2rem"
              style={(theme) => ({
                borderRadius: theme.spacing.xxs,
              })}
            >
              {icon}
            </ZorroCenter>
          )}
          <Text size="sm" ml="sm">
            {label}
          </Text>
        </ZorroFlex>
        <ZorroRadioIndicator />
      </ZorroGroup>
    </Radio.Card>
  );
};

ZorroRadioCard.displayName = 'ZorroRadioCard';
