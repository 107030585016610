import { yupResolver } from '@hookform/resolvers/yup';
import { formatDateISO, parseDateISO } from '@zorro/shared/formatters';
import {
  SUCCESS_MESSAGES,
  VALIDATION_MESSAGES,
  useForm,
} from '@zorro/shared/utils';
import {
  Button,
  Center,
  DateInput,
  FormErrorMessage,
  Grid,
  Space,
  Stack,
  Text,
} from '@zorro/zorro-ui-design';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { EmployeeRow } from '../../EmployeesDatatable';
import { InformationBoxComponent } from '../../InformationBox';
import { useMonolithMutation } from '../../hooks';

const terminateEmployeeFormSchema = yup.object({
  terminationDate: yup
    .date()
    .typeError(VALIDATION_MESSAGES.lastDateOfEmploymentRequired)
    .required(VALIDATION_MESSAGES.lastDateOfEmploymentRequired),
  eligibleUntil: yup
    .date()
    .typeError(VALIDATION_MESSAGES.lastDateOfBenefitEligibilityRequired)
    .required(VALIDATION_MESSAGES.lastDateOfBenefitEligibilityRequired),
});

type TerminateEmployeeFormFields = yup.InferType<
  typeof terminateEmployeeFormSchema
>;

type TerminateEmployeeFormProps = {
  selectedEmployees: Pick<
    EmployeeRow,
    'id' | 'fullName' | 'terminationDate' | 'eligibleUntil'
  >[];
  onSuccess?: () => void;
};

export function TerminateEmployeeForm({
  selectedEmployees,
  onSuccess,
}: TerminateEmployeeFormProps) {
  const singleEmployee = selectedEmployees[0];

  const { tryMutate: mutateEmployeeByAdmin } = useMonolithMutation({
    method: 'employeesControllerUpdateByAdmin',
    successMessage: SUCCESS_MESSAGES.EMPLOYEE_TERMINATED_SUCCESSFULLY_MESSAGE,
  });

  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting, errors },
  } = useForm<TerminateEmployeeFormFields>({
    mode: 'all',
    resolver: yupResolver(terminateEmployeeFormSchema),
    defaultValues: {
      terminationDate: singleEmployee?.terminationDate
        ? parseDateISO(singleEmployee.terminationDate).toDate()
        : undefined,
      eligibleUntil: singleEmployee?.eligibleUntil
        ? parseDateISO(singleEmployee.eligibleUntil).toDate()
        : undefined,
    },
  });

  if (!singleEmployee) {
    return null;
  }

  async function terminateEmployee(formFields: TerminateEmployeeFormFields) {
    const { terminationDate, eligibleUntil } = formFields;

    if (!(terminationDate && eligibleUntil)) {
      return;
    }

    const mutateResult = await mutateEmployeeByAdmin([
      singleEmployee.id,
      {
        terminationDate: formatDateISO(terminationDate),
        eligibleUntil: formatDateISO(eligibleUntil),
      },
    ]);

    if (mutateResult.isOk()) {
      onSuccess?.();
    }
  }

  return (
    <Stack>
      <InformationBoxComponent
        title="Here's what to expect:"
        items={[
          "After you terminate an employee on the Zorro platform, the Zorro team will cancel the employee's Zorro payment card",
          'The terminated employee will be notified with instructions on how to set-up payment independently, should they wish to continue their coverage',
        ]}
      />
      <Space h="0.5rem" />
      <Text
        size="1rem"
        fw={500}
      >{`${singleEmployee.fullName}’s termination details`}</Text>

      <form onSubmit={handleSubmit(terminateEmployee)}>
        <Grid>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="terminationDate"
              render={({
                field: { ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <DateInput {...rest} required label="Last date of employment" />
              )}
            />

            <FormErrorMessage errors={errors} fieldName="terminationDate" />
          </Grid.Col>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="eligibleUntil"
              render={({
                field: { ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <DateInput
                  {...rest}
                  required
                  label="Last date of benefit eligibility"
                />
              )}
            />
            <FormErrorMessage errors={errors} fieldName="eligibleUntil" />
          </Grid.Col>
        </Grid>
        <Space h="xl" />
        <Center>
          <Button type="submit" disabled={!isValid || isSubmitting}>
            Submit
          </Button>
        </Center>
      </form>
    </Stack>
  );
}
