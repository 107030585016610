import { IconCopy } from '@tabler/icons-react';
import { ActionIcon, brand } from '@zorro/zorro-ui-design';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import styles from './CopyToClipboardActionIcon.module.scss';

type CopyToClipboardActionIconProps = {
  numberToCopy: string;
};

const CopyToClipboardActionIcon = ({
  numberToCopy,
}: CopyToClipboardActionIconProps) => {
  return (
    <CopyToClipboard text={numberToCopy}>
      <ActionIcon
        variant="transparent"
        aria-label="copy-to-clipboard"
        classNames={styles}
      >
        <IconCopy color={brand.zorroWhite} />
      </ActionIcon>
    </CopyToClipboard>
  );
};

export default CopyToClipboardActionIcon;
