import { AppShellSideBar, AppShellSideBarProps } from '@zorro/shared/ui';
import { useImpersonation } from '@zorro/shared/utils';
import { HEADER_HEIGHT } from '@zorro/types';
import { ReactNode } from 'react';
import { HeaderSideBar } from '~/components/layouts/HeaderSideBar';

interface Props extends Omit<AppShellSideBarProps, 'headerComponent'> {
  children: ReactNode;
  shouldRemovePadding?: boolean;
}

export function DefaultLayout({
  children,
  shouldRemovePadding,
  ...props
}: Props) {
  const { user } = useImpersonation();

  if (!user) {
    return children;
  }

  const paddingProps = shouldRemovePadding
    ? // eslint-disable-next-line id-length
      { pb: 0, mainProps: { p: 0, pt: HEADER_HEIGHT } }
    : {};

  return (
    <AppShellSideBar
      headerComponent={
        <HeaderSideBar profilePicture={user.profilePictureUrl} />
      }
      {...paddingProps}
      {...props}
    >
      {children}
    </AppShellSideBar>
  );
}
