import { PaymentStatus, YesNoNotSure } from '@zorro/clients';
import { DeepReadonly } from '@zorro/types';

import { getPaymentIcon } from './PaymentStatusUtils';

const AWAITING_EMPLOYEE_REPORT = 'Awaiting employee report';

export const initialPremiumPaymentConfig: DeepReadonly<{
  [status in PaymentStatus]: { label: string; icon: JSX.Element };
}> = {
  [PaymentStatus.TRUE_BY_EMPLOYEE]: {
    label: 'Completed by employee',
    icon: getPaymentIcon(true, YesNoNotSure.YES),
  },
  [PaymentStatus.TRUE]: {
    label: 'Completed by agent',
    icon: getPaymentIcon(false, YesNoNotSure.YES),
  },
  [PaymentStatus.FALSE]: {
    label: 'Not completed - waiting for agent',
    icon: getPaymentIcon(false, YesNoNotSure.NO),
  },
  [PaymentStatus.FALSE_BY_EMPLOYEE]: {
    label: 'Not completed - waiting for employee',
    icon: getPaymentIcon(true, YesNoNotSure.NO),
  },
  [PaymentStatus.UNKNOWN_BY_EMPLOYEE]: {
    label: AWAITING_EMPLOYEE_REPORT,
    icon: getPaymentIcon(true, YesNoNotSure.NOT_SURE),
  },
};

export const autoPayConfig: DeepReadonly<{
  [status in PaymentStatus]: { label: string; icon: JSX.Element };
}> = {
  [PaymentStatus.TRUE_BY_EMPLOYEE]: {
    label: 'Set up by employee',
    icon: getPaymentIcon(true, YesNoNotSure.YES),
  },
  [PaymentStatus.TRUE]: {
    label: 'Set up by agent',
    icon: getPaymentIcon(false, YesNoNotSure.YES),
  },
  [PaymentStatus.FALSE]: {
    label: 'Not set up - waiting for agent',
    icon: getPaymentIcon(false, YesNoNotSure.NO),
  },
  [PaymentStatus.FALSE_BY_EMPLOYEE]: {
    label: 'Not set up - waiting for employee',
    icon: getPaymentIcon(true, YesNoNotSure.NO),
  },
  [PaymentStatus.UNKNOWN_BY_EMPLOYEE]: {
    label: AWAITING_EMPLOYEE_REPORT,
    icon: getPaymentIcon(true, YesNoNotSure.NOT_SURE),
  },
};
