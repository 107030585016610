import { EmployeePaymentMethodType } from '@zorro/clients';
import {
  IconZorroPaymentCard,
  IconZorroPaymentCombined,
  IconZorroPaymentSelfPay,
} from '@zorro/shared/assets';
import { brand } from '@zorro/zorro-ui-design';

import { TCardSelectorOption } from '../../CardSelector/cardSelector.types';

export const PAYMENT_METHOD_TYPE_SELECT_OPTIONS: TCardSelectorOption[] = [
  {
    value: EmployeePaymentMethodType.ZORRO_PAY,
    label: 'Zorro Pay',
    description:
      'Payments paid from Zorro Pay and amounts above allowance deducted through payroll',
    icon: IconZorroPaymentCard,
    iconBgColor: brand.zorroIris100,
  },
  {
    value: EmployeePaymentMethodType.COMBINED,
    label: 'Combined',
    description:
      "Payments split between Zorro Pay and employee's personal card/account",
    icon: IconZorroPaymentCombined,
    iconBgColor: brand.zorroSky100,
  },
  {
    value: EmployeePaymentMethodType.SELF_PAY,
    label: 'Self-pay (reimbursement)',
    description:
      "Payments paid from employee's personal card/account and allowance reimbursed through payroll",
    icon: IconZorroPaymentSelfPay,
    iconBgColor: brand.zorroYolk400,
  },
];
