import { IconSettings } from '@tabler/icons-react';
import {
  ZORRO_UI_DYNAMIC_ROUTES,
  createImpersonationSession,
  useRoles,
} from '@zorro/shared/utils';
import { Menu, Text } from '@zorro/zorro-ui-design';

import { ActionsSubMenu } from '../ActionsSubMenu';
import { EmployeeActionsOverlays } from '../employeeActions.consts';
import { ActionsMenuSection } from '../employeeActions.types';
import { resetPassword } from './accountManagement.utils';

const AccountManagementSection = ({
  setOverlayId,
  selectedEmployees,
}: ActionsMenuSection) => {
  const { isZorroOperations } = useRoles();

  const isBulk = selectedEmployees.length > 1;

  return (
    <ActionsSubMenu title="Account management" icon={IconSettings}>
      <Menu.Item
        onClick={() =>
          setOverlayId(EmployeeActionsOverlays.CHANGE_EMPLOYEE_ROLE)
        }
      >
        <Text size="sm">{EmployeeActionsOverlays.CHANGE_EMPLOYEE_ROLE}</Text>
      </Menu.Item>
      {!isBulk && (
        <>
          <Menu.Item
            onClick={() => {
              createImpersonationSession(
                selectedEmployees[0].id,
                ZORRO_UI_DYNAMIC_ROUTES.MY_COVERAGE()
              );
            }}
          >
            <Text size="sm">Log in as</Text>
          </Menu.Item>
          {isZorroOperations && (
            <Menu.Item
              onClick={async () => {
                await resetPassword(selectedEmployees[0].userId);
              }}
            >
              <Text size="sm">Reset password</Text>
            </Menu.Item>
          )}
        </>
      )}
      <Menu.Item
        onClick={() => setOverlayId(EmployeeActionsOverlays.DELETE_EMPLOYEE)}
      >
        <Text size="sm">{isBulk ? 'Delete employees' : 'Delete employee'}</Text>
      </Menu.Item>
    </ActionsSubMenu>
  );
};

export { AccountManagementSection };
