import { UnstyledButton } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';

import classNames from './ExportButton.module.scss';

type Props = {
  onDownload: () => void;
  isDisabled?: boolean;
};

export const DownloadButton = ({ onDownload, isDisabled = false }: Props) => {
  return (
    <UnstyledButton
      size={28}
      classNames={classNames}
      onClick={onDownload}
      disabled={isDisabled}
    >
      <IconDownload />
    </UnstyledButton>
  );
};
