import { Card, CardProps } from '@mantine/core';
import { MouseEventHandler, forwardRef } from 'react';

import classNames from './Card.module.scss';

export type ICardProps = CardProps & {
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const ZorroCard = forwardRef<HTMLDivElement, ICardProps>(
  (
    // eslint-disable-next-line id-length
    { withBorder = true, shadow = undefined, p = 'xl', ...props },
    ref
  ) => {
    return (
      <Card
        ref={ref}
        withBorder={withBorder}
        classNames={classNames}
        shadow={shadow}
        p={p}
        {...props}
      />
    );
  }
);

ZorroCard.displayName = 'ZorroCard';
