import { IconChevronUp } from '@tabler/icons-react';
import { EMPLOYEE_SIDEBAR_ZINDEX, HEADER_HEIGHT } from '@zorro/types';
import { Group, Icon, Stack, Text, brand } from '@zorro/zorro-ui-design';
import { ElementType, Fragment, useState } from 'react';

import { EmployeePageAction } from '../EmployeePageActions';
import classNames from './EmployeePageSidebar.module.scss';
import { EmployeePageSidebarHeader } from './EmployeePageSidebarHeader';
import {
  EmployeePageSidebarSubitem,
  EmployeePageSidebarSubitemProps,
} from './EmployeePageSidebarSubitem';

export enum EmployeePageSidebarType {
  GENERAL_NOTES = 'GENERAL_NOTES',
  ENROLLMENTS = 'ENROLLMENTS',
}

export type SidebarItemProps = {
  page: EmployeePageSidebarType;
  label: string;
  icon: ElementType;
  subitems: EmployeePageSidebarSubitemProps[];
  onClick?: () => void;
  isChecked?: boolean;
};

type Props = {
  page: EmployeePageSidebarType;
  items: SidebarItemProps[];
  employerId: string;
  employeeId: string;
  setAction: (action: EmployeePageAction) => void;
};

export const EmployeePageSidebar = ({
  page: currentPage,
  items,
  employerId,
  employeeId,
  setAction,
}: Props) => {
  const [openedPage, setOpenedPage] = useState<
    EmployeePageSidebarType | undefined
  >(currentPage);

  const handleClick = ({
    page,
    subitems,
    onClick,
  }: Pick<SidebarItemProps, 'page' | 'subitems' | 'onClick'>) => {
    if (subitems.length > 0) {
      setOpenedPage((prev) => {
        return prev === page ? undefined : page;
      });
    }

    onClick?.();
  };

  return (
    <Stack
      style={{
        borderRight: `1px solid ${brand.zorroGray200}`,
        zIndex: EMPLOYEE_SIDEBAR_ZINDEX,
        overflowY: 'auto',
      }}
      h={`calc(100dvh - ${HEADER_HEIGHT}px)`}
      bg="zorroGray.10"
      pos="fixed"
      w="22.5rem"
      gap="xs"
      p="md"
    >
      <EmployeePageSidebarHeader
        setAction={setAction}
        employerId={employerId}
        employeeId={employeeId}
      />

      {items.map(({ page, label, icon, subitems, onClick }) => {
        const isChecked = subitems.length === 0 ? page === currentPage : false;
        const isOpen = page === openedPage;
        return (
          <Fragment key={page}>
            <Group
              onClick={() => handleClick({ page, subitems, onClick })}
              data-checked={isChecked}
              classNames={classNames}
            >
              <Icon icon={icon} color={brand.zorroGray400} />
              <Group justify="space-between" w="100%">
                <Text>{label}</Text>
                {subitems.length > 0 && (
                  <Icon
                    icon={IconChevronUp}
                    style={{
                      rotate: isOpen ? '-180deg' : undefined,
                      transition: 'rotate 0.25s',
                    }}
                  />
                )}
              </Group>
            </Group>
            {isOpen && <EmployeePageSidebarSubitem subitems={subitems} />}
          </Fragment>
        );
      })}
    </Stack>
  );
};
