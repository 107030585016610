import { IconChevronDown } from '@tabler/icons-react';
import { useRoles } from '@zorro/shared/utils';
import { Button, Menu } from '@zorro/zorro-ui-design';

import { AccountManagementSection } from './AccountManagement/AccountManagementSection';
import { EmailCommunicationsSection } from './EmailCommunications/EmailCommunicationsSection';
import { EmployeeAdministrationSection } from './EmployeeAdministration/EmployeeAdministrationSection';
import { EnrollmentActionsSection } from './EnrollmentActions/EnrollmentActionsSection';
import { menuOffset } from './employeeActions.consts';
import { ActionsMenuSection } from './employeeActions.types';

type ActionMenuProps = {
  selectedEmployees: ActionsMenuSection['selectedEmployees'];
  setOverlayId: ActionsMenuSection['setOverlayId'];
};

export function ActionsMenu({
  selectedEmployees,
  setOverlayId,
}: ActionMenuProps) {
  const { isManagement } = useRoles();

  const selectedEmployeesCount = selectedEmployees.length;

  return (
    <Menu
      disabled={selectedEmployeesCount === 0}
      position="bottom-end"
      offset={menuOffset}
      loop={false}
    >
      <Menu.Target>
        <Button
          disabled={selectedEmployeesCount === 0}
          variant="primary"
          size="xs"
          fw={400}
          fz="sm"
          rightSection={
            <IconChevronDown width="1.1875rem" height="1.1875rem" />
          }
        >
          Actions
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <EmailCommunicationsSection
          setOverlayId={setOverlayId}
          selectedEmployees={selectedEmployees}
        />

        <Menu.Divider />

        {isManagement && (
          <>
            <EnrollmentActionsSection
              setOverlayId={setOverlayId}
              selectedEmployees={selectedEmployees}
            />
            <Menu.Divider />
          </>
        )}

        <EmployeeAdministrationSection
          setOverlayId={setOverlayId}
          selectedEmployees={selectedEmployees}
        />

        <Menu.Divider />

        <AccountManagementSection
          setOverlayId={setOverlayId}
          selectedEmployees={selectedEmployees}
        />
      </Menu.Dropdown>
    </Menu>
  );
}
