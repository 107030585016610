import {
  FullStory,
  isInitialized as isInitializedFullStory,
} from '@fullstory/browser';
import { formatDateTimeISO, getNow } from '@zorro/shared/formatters';
import {
  callEndpoint,
  getExternalIP,
  useImpersonation,
} from '@zorro/shared/utils';
import { useCallback } from 'react';

import { getFullStoryTrackEventProperties } from './analytics.utils';

export function useAnalytics() {
  const { employee, isImpersonated, sessionId } = useImpersonation();

  const sendEvent = useCallback(
    async (name: string, payload?: object) => {
      if (isInitializedFullStory()) {
        FullStory('trackEvent', {
          name,
          properties: getFullStoryTrackEventProperties(payload),
        });
      }

      if (employee) {
        const ip = await getExternalIP();

        await callEndpoint({
          method: 'eventsControllerCollect',
          params: [
            [
              {
                name,
                pageUrl: window.location.href,
                userAgent: navigator.userAgent,
                referer: document.referrer,
                sessionId,
                ip,
                timestamp: formatDateTimeISO(getNow()),
                employeeId: employee.id,
                isImpersonated,
                impersonatedEmployeeId: isImpersonated ? employee.id : null,
                payload,
              },
            ],
          ],
        });
      }
    },
    [employee, isImpersonated, sessionId]
  );

  return { sendEvent };
}
