import { Roles } from '@zorro/types';

import {
  isAgentRole,
  isAgentWithoutSupervisor,
  isEmployerAdminRole,
  isManagement,
  isZorroOperationsRole,
} from '../roles';
import { useImpersonation } from './useImpersonation';

export function useRoles() {
  const { user } = useImpersonation();

  const roles: Roles[] = user?.roles.map(({ key }) => key as Roles) ?? [];

  return {
    userRoles: roles,
    isZorroOperations: isZorroOperationsRole(roles),
    isAgent: isAgentRole(roles),
    isAgentWithoutSupervisor: isAgentWithoutSupervisor(roles),
    isEmployerAdmin: isEmployerAdminRole(roles),
    isManagement: isManagement(roles),
  };
}
