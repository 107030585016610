import { yupResolver } from '@hookform/resolvers/yup';
import { InsuredPeopleDto } from '@zorro/clients';
import {
  DateUtilInstance,
  formatDateISO,
  getNow,
  parseDateISO,
} from '@zorro/shared/formatters';
import {
  callEndpoint,
  insuredMinDateOfBirth,
  isDefined,
  logger,
  useForm,
  useImpersonation,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import { YesNo } from '@zorro/types';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useLoadingOverlay } from '../LoadingOverlayContext';
import { useMonolithMutation } from '../hooks';
import {
  InsuredDependentsFormFields,
  getDependentInsuredSchema,
} from './DependentsFormInputs';
import {
  InsuredEmployeeFormFields,
  getEmployeeFormSchema,
} from './EmployeeFormInputs';
import {
  getDefaultInsuredFormFields,
  mapInsuredDtoToBaseInsuredFormFields,
} from './InsuredFormUtils';
import {
  InsuredSpouseFormFields,
  getSpouseInsuredSchema,
} from './SpouseFormInputs';

type UseInsuredForm = (props: {
  employeeId: string;
  onboardingPeriodId: string;
  isFinalizationMode: boolean;
  isEmployeeMode: boolean;
}) => {
  employeeForm: UseFormReturn<InsuredEmployeeFormFields>;
  spouseForm: UseFormReturn<InsuredSpouseFormFields>;
  dependentsForm: UseFormReturn<InsuredDependentsFormFields>;
  targetEnrollmentDate: DateUtilInstance;
  handleAddressValidation: () => Promise<boolean>;
  insured: InsuredPeopleDto | undefined;
  onSubmit: () => Promise<boolean>;
  hasAnyDependents: boolean;
  setIsSpouseActive: (isActive: boolean) => void;
  isSpouseActive: boolean;
};

export const useInsuredForm: UseInsuredForm = ({
  onboardingPeriodId,
  employeeId,
  isFinalizationMode,
  isEmployeeMode,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { startLoading, stopLoading } = useLoadingOverlay();
  const { isAgent, isZorroOperations, isEmployerAdmin } = useRoles();
  const { isImpersonated } = useImpersonation();

  const { mutate: mutateEmployee } = useMonolithMutation(
    isZorroOperations || isAgent || isEmployerAdmin || isImpersonated
      ? {
          method: 'employeesControllerUpdateByAdmin',
          shouldInvalidateQueries: false,
        }
      : {
          method: 'employeesControllerUpdateSelf',
          shouldInvalidateQueries: false,
        }
  );
  const { mutate: mutateInsured } = useMonolithMutation({
    method: 'insuredControllerUpsertInsuredForPeriod',
  });

  const [isSpouseActive, setIsSpouseActive] = useState<boolean>(false);
  const [cachedAddressResults, setCachedAddressResults] = useState<
    Record<string, boolean | undefined>
  >({});

  const { data: me } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });
  const { data: onboardingPeriods = [] } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindMany',
    params: [employeeId],
  });

  const onboardingPeriod = onboardingPeriods.find(
    ({ id }) => onboardingPeriodId === id
  );
  const lastSubmittedEnrollmentPeriodId =
    onboardingPeriods.length > 0 &&
    onboardingPeriods.reduce((prev, current) => {
      return parseDateISO(prev.targetEnrollmentDate).isAfter(
        parseDateISO(current.targetEnrollmentDate)
      )
        ? prev
        : current;
    })?.id;

  const { data: insured } = useMonolithQuery({
    method: 'insuredControllerFindInsuredForPeriod',
    params: [onboardingPeriodId],
  });
  const { data: lastSubmittedInsured } = useMonolithQuery({
    method: 'insuredControllerFindInsuredForPeriod',
    params: Boolean(insured) &&
      lastSubmittedEnrollmentPeriodId && [lastSubmittedEnrollmentPeriodId],
  });

  const insuredPeople = insured ?? lastSubmittedInsured;
  const employee = insuredPeople?.employee;

  const employeeForm = useForm<InsuredEmployeeFormFields>({
    mode: 'all',
    resolver: yupResolver(
      getEmployeeFormSchema(isFinalizationMode, isEmployeeMode)
    ),
    defaultValues:
      insuredPeople && me
        ? ({
            ...mapInsuredDtoToBaseInsuredFormFields(employee || me),
            email: me.email,
            phone: me.phone || '',
            personalEmail: me.personalEmail || '',
            residentialAddress:
              employee?.residentialAddress || me.address || '',
            isMailingAddressSameAsResidentialAddress:
              employee?.residentialAddress && employee?.mailingAddress
                ? employee.residentialAddress === employee.mailingAddress
                : true,
            mailingAddress: employee?.mailingAddress || '',
            citizenshipStatus: employee?.citizenshipStatus || null,
            employmentType: employee?.employmentType || null,
            wageType: employee?.wageType || null,
            class: me?.class || '',
          } satisfies InsuredEmployeeFormFields)
        : undefined,
  });

  const spouseForm = useForm<InsuredSpouseFormFields>({
    mode: 'all',
    resolver: yupResolver(getSpouseInsuredSchema(isFinalizationMode)),
    defaultValues: insuredPeople?.spouse
      ? {
          ...mapInsuredDtoToBaseInsuredFormFields(insuredPeople.spouse),
          subtype: insuredPeople.spouse.subtype || undefined,
        }
      : getDefaultInsuredFormFields(employee?.residentialAddress),
  });

  const targetEnrollmentDate = onboardingPeriod?.targetEnrollmentDate
    ? parseDateISO(onboardingPeriod.targetEnrollmentDate)
    : getNow();

  const dependentsForm = useForm<InsuredDependentsFormFields>({
    mode: 'all',
    defaultValues: {
      dependents:
        insuredPeople?.dependents && insuredPeople.dependents.length > 0
          ? insuredPeople.dependents.map((dependent) => ({
              ...mapInsuredDtoToBaseInsuredFormFields(dependent),
              subtype: dependent.subtype || undefined,
            }))
          : [],
    },
    resolver: yupResolver(
      getDependentInsuredSchema(
        isFinalizationMode,
        insuredMinDateOfBirth(true, targetEnrollmentDate)
      )
    ),
  });

  const hasAnyDependents = dependentsForm.watch('dependents').length > 0;
  const employeeAddress = employeeForm.watch('residentialAddress');

  useEffect(() => {
    if (!isEmployeeMode || !employeeAddress || !insuredPeople) {
      return;
    }

    const hasEmployeeAddressChanged =
      employeeAddress !== insuredPeople.employee.residentialAddress;

    if (
      !insuredPeople.spouse?.residentialAddress ||
      hasEmployeeAddressChanged
    ) {
      spouseForm.setValue('residentialAddress', employeeAddress);
    }

    insuredPeople.dependents.forEach((dependent, index) => {
      if (!dependent.residentialAddress || hasEmployeeAddressChanged) {
        dependentsForm.setValue(
          `dependents.${index}.residentialAddress`,
          employeeAddress
        );
      }
    });
  }, [
    employeeAddress,
    spouseForm,
    dependentsForm,
    insuredPeople,
    isEmployeeMode,
  ]);

  const handleAddressValidation = async () => {
    if (!employeeAddress) return false;
    const cachedResult = cachedAddressResults[employeeAddress];
    const validateAddress = isDefined(cachedResult)
      ? { isValidAddress: cachedResult }
      : await callEndpoint({
          method: 'addressControllerValidateAddress',
          params: [{ address: employeeAddress }],
        });

    const isValidAddress = validateAddress?.isValidAddress || false;
    if (!isValidAddress) {
      employeeForm.setError('residentialAddress', {
        message:
          "Your address couldn't be verified. Try choosing it from the search results.",
      });
    }
    setCachedAddressResults((prev) => ({
      ...prev,
      [employeeAddress]: isValidAddress,
    }));

    return isValidAddress;
  };

  return {
    employeeForm,
    spouseForm,
    dependentsForm,
    targetEnrollmentDate,
    insured,
    hasAnyDependents,
    isSpouseActive,
    setIsSpouseActive,
    handleAddressValidation,
    onSubmit: async (): Promise<boolean> => {
      if (!onboardingPeriod) return false;

      startLoading();
      const validationResults = await Promise.all([
        employeeForm.trigger(),
        isSpouseActive ? spouseForm.trigger() : true,
        hasAnyDependents ? dependentsForm.trigger() : true,
      ]);

      if (validationResults.some((result) => !result)) {
        stopLoading();
        return false;
      }

      if (isFinalizationMode) {
        const isValidAddress = await handleAddressValidation();
        if (!isValidAddress) {
          stopLoading();
          return false;
        }
      }

      const {
        email,
        phone,
        personalEmail,
        isMailingAddressSameAsResidentialAddress,
        class: employeeClass,
        ...employee
      } = employeeForm.getValues();
      const spouse = spouseForm.getValues();
      const { dependents } = dependentsForm.getValues();

      const mailingAddress = isMailingAddressSameAsResidentialAddress
        ? employee.residentialAddress
        : employee.mailingAddress;

      let result = false;
      try {
        await mutateEmployee([
          employeeId,
          {
            dateOfBirth: formatDateISO(employee.dateOfBirth),
            address: employee.residentialAddress,
            personalEmail: personalEmail || null,
            class: employeeClass || undefined,
            firstName: employee.firstName,
            lastName: employee.lastName,
            gender: employee.gender,
            phone,
          },
        ]);

        await mutateInsured([
          onboardingPeriodId,
          {
            employee: {
              ...employee,
              dateOfBirth: formatDateISO(employee.dateOfBirth),
              isPregnant: employee.isPregnant === YesNo.YES,
              isSmoker: employee.isSmoker === YesNo.YES,
              mailingAddress,
            },
            spouse: isSpouseActive
              ? {
                  ...spouse,
                  dateOfBirth: formatDateISO(spouse.dateOfBirth),
                  isPregnant: spouse.isPregnant === YesNo.YES,
                  isSmoker: spouse.isSmoker === YesNo.YES,
                  residentialAddress: spouse.residentialAddress,
                }
              : undefined,
            dependents: dependents.map((dependent) => ({
              ...dependent,
              dateOfBirth: formatDateISO(dependent.dateOfBirth),
              isPregnant: spouse.isPregnant === YesNo.YES,
              isSmoker: spouse.isSmoker === YesNo.YES,
              residentialAddress: dependent.residentialAddress,
            })),
          },
        ]);

        result = true;
      } catch (error) {
        logger.error(error);
      } finally {
        stopLoading();
      }
      return result;
    },
  };
};
