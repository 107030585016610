import { ComboboxItem } from '@mantine/core';
import { OnboardingPeriodReportDto } from '@zorro/clients';
import {
  formatDateEnLocaleRange,
  parseDateISO,
} from '@zorro/shared/formatters';
import { useMonolithQuery } from '@zorro/shared/utils';
import { Select } from '@zorro/zorro-ui-design';
import { useMemo } from 'react';

type EmployeeBenefitEnrollment = {
  effectiveFrom: string;
  effectiveUntil: string;
  benefitId: string;
  periodId: string;
};

const mapOnboardingPeriodsToSelectOptions = (
  onboardingPeriods: OnboardingPeriodReportDto[],
  benefitEnrollments: EmployeeBenefitEnrollment[],
  onboardingPeriodsFilter: (
    onboardingPeriod: OnboardingPeriodReportDto
  ) => boolean
): ComboboxItem[] => {
  return onboardingPeriods
    .filter((value) => onboardingPeriodsFilter(value))
    .sort((first, second) =>
      parseDateISO(first.onboardingFrom).isAfter(
        parseDateISO(second.onboardingFrom),
        'day'
      )
        ? -1
        : 1
    )
    .map(({ id, isActive, isSpecialEnrollment, enrollmentEffectiveFrom }) => {
      const benefitEnrollment = benefitEnrollments.find(
        ({ periodId }) => id === periodId
      );

      let label: string;
      if (benefitEnrollment) {
        label = formatDateEnLocaleRange(
          benefitEnrollment.effectiveFrom,
          benefitEnrollment.effectiveUntil
        );
      } else if (isActive) {
        label = 'Upcoming enrollment';
      } else if (isSpecialEnrollment) {
        label = `Special enrollment ${parseDateISO(
          enrollmentEffectiveFrom
        ).year()}`;
      } else {
        label = `Open enrollment ${parseDateISO(
          enrollmentEffectiveFrom
        ).year()}`;
      }

      return {
        value: id,
        label,
      };
    });
};

type Props = {
  employeeId: string;
  onSelectedPeriodChange: (newSelectedPeriodId: string) => void;
  selectedPeriodId?: string;
  onboardingPeriodFilter?: (
    onboardingPeriod: OnboardingPeriodReportDto
  ) => boolean;
};

export const OnboardingPeriodSelect = ({
  employeeId,
  onSelectedPeriodChange,
  selectedPeriodId,
  onboardingPeriodFilter = () => true,
}: Props) => {
  const { isLoading, data: onboardingPeriodsReport = [] } = useMonolithQuery({
    method: 'onboardingPeriodReportsControllerGenerateEmployeeOnboardingReport',
    params: [employeeId],
  });

  const options = useMemo(() => {
    const benefitEnrollments = onboardingPeriodsReport.flatMap(
      (onboardingPeriodReport) =>
        onboardingPeriodReport.majorMedicalBenefit?.benefitEnrollment
          ? [
              {
                effectiveFrom:
                  onboardingPeriodReport.majorMedicalBenefit.benefitEnrollment
                    .effectiveFrom,
                effectiveUntil:
                  onboardingPeriodReport.majorMedicalBenefit.benefitEnrollment
                    .effectiveUntil,
                benefitId: onboardingPeriodReport.majorMedicalBenefit.id,
                periodId: onboardingPeriodReport.id,
              },
            ]
          : []
    );
    return mapOnboardingPeriodsToSelectOptions(
      onboardingPeriodsReport,
      benefitEnrollments,
      onboardingPeriodFilter
    );
  }, [onboardingPeriodsReport, onboardingPeriodFilter]);

  const isNotReady = isLoading || (!selectedPeriodId && options.length > 0);
  const isNoOptions = options.length <= 1;

  if (isNotReady || isNoOptions) {
    return null;
  }

  const value = options.find(({ value }) => selectedPeriodId === value)?.value;

  return (
    <Select
      data={options}
      value={value}
      onChange={(newSelectedPeriodId) => {
        if (newSelectedPeriodId) {
          onSelectedPeriodChange(newSelectedPeriodId);
        }
      }}
      size="sm"
      allowDeselect={false}
      styles={() => ({
        input: {
          border: 'none',
          paddingLeft: 0,
          minWidth: '16rem',
        },
      })}
    />
  );
};
