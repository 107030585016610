import { ADD_EMPLOYER_DYNAMIC_ROUTES } from '@zorro/shared/utils';
import { STEPPER_FLOW_STYLES } from '@zorro/types';
import {
  Card,
  Group,
  IStepperStepProps,
  Link,
  Stepper,
  Title,
} from '@zorro/zorro-ui-design';

import { useCompanyDetailsForm } from '../../CompanyDetailsForm';
import { useCompanySetupForm } from '../../CompanySetupForm';
import { useContactsForm } from '../../ContactsForm';
import { EmployerDotsMenu } from '../../EmployerDotsMenu';
import { usePlanYearSetupForm } from '../../PlanYearSetupForm';
import { useZorroSetupForm } from '../../ZorroSetupForm';
import {
  AddEmployerStepType,
  getAddEmployerStepConfig,
} from './AddEmployerUtils';

type StepperLinkProps = IStepperStepProps & {
  employerStep: AddEmployerStepType;
  employerId?: string;
  isValid?: boolean;
};

const StepperLink = ({
  employerStep,
  employerId = '',
  isValid,
  ...props
}: StepperLinkProps) => {
  const { route, label, shouldShowMissingDetails } =
    getAddEmployerStepConfig(employerStep);

  if (!employerId) {
    return <Stepper.Step {...props} label={label} />;
  }

  const stepIconProps = {
    isPartial: !isValid && !shouldShowMissingDetails,
    isPartialAndRequired: !isValid && shouldShowMissingDetails,
  };

  return (
    <Link
      href={ADD_EMPLOYER_DYNAMIC_ROUTES[route](employerId)}
      anchorProps={{ underline: 'never' }}
    >
      <Stepper.Step {...props} {...stepIconProps} label={label} isNavigable />
    </Link>
  );
};

type Props = {
  isFinalizationMode: boolean;
  employerStep: AddEmployerStepType;
  isDon: boolean;
  employerId?: string;
};

export const AddEmployerStepper = ({
  employerStep,
  employerId,
  isFinalizationMode,
  isDon,
}: Props) => {
  const { stepIndex } = getAddEmployerStepConfig(employerStep);
  const { companyDetailsForm } = useCompanyDetailsForm({
    employerId,
    isFinalizationMode,
  });
  const { contactsForm } = useContactsForm(employerId, isFinalizationMode);
  const { companySetupForm } = useCompanySetupForm(
    employerId,
    isFinalizationMode
  );
  const { zorroSetupForm } = useZorroSetupForm({ employerId });
  const { planYearSetupForm } = usePlanYearSetupForm({
    shouldFallbackToEarliestPeriod: true,
    isFinalizationMode,
    employerId,
  });

  return (
    <Card withBorder={false} bg="zorroGray.10" style={{ borderRadius: 8 }}>
      <Group>
        <Title>Add new employer</Title>
        {employerId && !isDon && <EmployerDotsMenu employerId={employerId} />}
      </Group>
      <Stepper
        styles={STEPPER_FLOW_STYLES}
        allowNextStepsSelect={false}
        active={stepIndex}
        variant="modern"
        wrap={false}
        size="sm"
        mt="lg"
      >
        <StepperLink
          isValid={companyDetailsForm.formState.isValid}
          employerStep={AddEmployerStepType.COMPANY_DETAILS}
          employerId={employerId}
        />
        <StepperLink
          isValid={contactsForm.formState.isValid}
          employerStep={AddEmployerStepType.CONTACTS}
          employerId={employerId}
        />
        <StepperLink
          isValid={companySetupForm.formState.isValid}
          employerStep={AddEmployerStepType.COMPANY_SETUP}
          employerId={employerId}
        />
        {!isDon && (
          <StepperLink
            isValid={zorroSetupForm.formState.isValid}
            employerStep={AddEmployerStepType.ZORRO_SETUP}
            employerId={employerId}
          />
        )}
        {!isDon && (
          <StepperLink
            isValid={planYearSetupForm.formState.isValid}
            employerStep={AddEmployerStepType.PLAN_YEAR_SETUP}
            employerId={employerId}
          />
        )}
        <StepperLink
          employerStep={AddEmployerStepType.REVIEW}
          employerId={employerId}
        />
      </Stepper>
    </Card>
  );
};
