import { EmployeeDto, OnboardingPeriodDto } from '@zorro/clients';
import { useMonolithQuery } from '@zorro/shared/utils';

import {
  CardSelector,
  PAYMENT_METHOD_TYPE_SELECT_OPTIONS,
} from '../../../index';

type PaymentMethodTypeProps = {
  employeeId: EmployeeDto['id'];
  onboardingPeriodId: OnboardingPeriodDto['id'];
};

const PaymentMethodType = ({
  employeeId,
  onboardingPeriodId,
}: PaymentMethodTypeProps) => {
  const {
    data: paymentMethodDetails,
    isLoading: isLoadingPaymentMethodDetails,
  } = useMonolithQuery({
    method: 'paymentsControllerEmployeePaymentMethodDetails',
    params: [employeeId, { onboardingPeriodId }],
  });

  if (isLoadingPaymentMethodDetails || !paymentMethodDetails) {
    return null;
  }

  return (
    <CardSelector
      selectData={PAYMENT_METHOD_TYPE_SELECT_OPTIONS}
      selectedValue={paymentMethodDetails.type}
      readonly
    />
  );
};

export { PaymentMethodType };
