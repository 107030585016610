import { CancelIcon } from '@zorro/shared/assets';
import {
  Button,
  Flex,
  Group,
  Modal,
  Space,
  Text,
  brand,
} from '@zorro/zorro-ui-design';

export type DeleteDocumentConfirmationModalProps = {
  isModalOpen: boolean;
  documentName: string | null;
  setIsModalOpen: (open: boolean) => void;
  performDeleteAction: () => Promise<void>;
};

export const DeleteDocumentConfirmationModal = ({
  isModalOpen,
  documentName,
  setIsModalOpen,
  performDeleteAction,
}: DeleteDocumentConfirmationModalProps) => {
  const handleOnClick = async () => {
    await performDeleteAction();
    setIsModalOpen(false);
  };

  return (
    <Modal
      size="lg"
      opened={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      showSeparator={false}
      title={
        <Flex align="center">
          <CancelIcon />

          <Text size="xl" ml="sm" fw={500}>
            Delete document
          </Text>
        </Flex>
      }
    >
      <Text fw={500}>
        Are you sure you want to delete this document?
        <Space h="xs" />
        <Text
          fw={500}
          c={brand.zorroGray400}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {documentName}
        </Text>
      </Text>
      <Group mt="xl" justify="flex-end">
        <Button
          onClick={() => setIsModalOpen(false)}
          variant="transparent"
          color={brand.zorroCoal900}
          bg="transparent"
          size="md"
          fw="400"
        >
          Cancel
        </Button>
        <Button
          onClick={handleOnClick}
          bg={brand.zorroFire700}
          size="md"
          fw="400"
        >
          Delete document
        </Button>
      </Group>
    </Modal>
  );
};
