import { IconSearch } from '@tabler/icons-react';
import { AllAgenciesDto } from '@zorro/clients';
import { EN_LOCALE_DATE_FORMAT } from '@zorro/shared/formatters';
import { useMonolithQuery, useRoles } from '@zorro/shared/utils';
import {
  FormErrorMessage,
  Grid,
  Icon,
  LabelWithTooltip,
  MonthPickerInput,
  MultiSelect,
  NumberInput,
  Select,
  TagsInput,
} from '@zorro/zorro-ui-design';
import { Controller, UseFormReturn } from 'react-hook-form';

import { ZorroSetupFormFields, tagsInputStyles } from './ZorroSetupUtils';

type Props = {
  zorroSetupForm: UseFormReturn<ZorroSetupFormFields>;
  allAgencies: AllAgenciesDto;
  shouldShowCoverageStartDate?: boolean;
  isFinalizationMode?: boolean;
};

export const ZorroSetupForm = ({
  zorroSetupForm,
  allAgencies,
  shouldShowCoverageStartDate = false,
  isFinalizationMode,
}: Props) => {
  const {
    isAgent,
    isAgentWithoutSupervisor: isReadonlyMode,
    isZorroOperations,
  } = useRoles();

  const { zorroPartners, enrollmentTeams, producers } = allAgencies;
  const { control, formState, watch } = zorroSetupForm;
  const { errors } = formState;

  const producerId = watch('producerId');
  const { data: producerAgents = [] } = useMonolithQuery({
    method: 'agentsControllerFindAllForAgency',
    params: producerId && [producerId],
  });

  return (
    <Grid>
      <Grid.Col>
        <Controller
          control={control}
          name="zorroPartnerId"
          render={({ field }) => (
            <Select
              {...field}
              placeholder="Select a partner"
              data={zorroPartners.map((zorroPartner) => ({
                label: zorroPartner.name,
                value: zorroPartner.id,
              }))}
              disabled={isAgent}
              size="md"
              searchable
              label={
                <LabelWithTooltip
                  label="Zorro Partner"
                  tooltipLabel="Entity responsible for ICHRA plan design and set up"
                  isRequired
                />
              }
            />
          )}
        />

        <FormErrorMessage fieldName="zorroPartnerId" errors={errors} />
      </Grid.Col>

      <Grid.Col>
        <Controller
          name="enrollmentTeamIds"
          control={control}
          render={({ field }) => (
            <MultiSelect
              {...field}
              variant="rectangle"
              data={[...enrollmentTeams, ...zorroPartners].map((agency) => ({
                label: agency.name,
                value: agency.id,
              }))}
              disabled={isReadonlyMode}
              placeholder="Select enrollment team(s)"
              size="md"
              searchable
              rightSection={
                <Icon icon={IconSearch} style={{ marginRight: '12px' }} />
              }
              label={
                <LabelWithTooltip
                  label="Enrollment team(s)"
                  tooltipLabel="Entity responsible for enrolling employees with carriers"
                  isRequired={isFinalizationMode}
                />
              }
            />
          )}
        />
        <FormErrorMessage fieldName="enrollmentTeamIds" errors={errors} />
      </Grid.Col>

      <Grid.Col span={6}>
        <Controller
          name="producerId"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data={[...producers, ...zorroPartners].map((agency) => ({
                label: agency.name,
                value: agency.id,
              }))}
              searchable
              disabled={isReadonlyMode}
              placeholder="Select a producer agency"
              size="md"
              label={
                <LabelWithTooltip
                  label="Producer agency"
                  tooltipLabel="Entity responsible for managing the employer’s benefits"
                  isRequired={isFinalizationMode}
                />
              }
            />
          )}
        />
        <FormErrorMessage fieldName="producerId" errors={errors} />
      </Grid.Col>

      <Grid.Col span={6}>
        <Controller
          name="producerAgentId"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data={producerAgents.map((agent) => ({
                label: `${agent.firstName} ${agent.lastName} (${agent.email})`,
                value: agent.id,
              }))}
              searchable
              disabled={isReadonlyMode || !producerId}
              placeholder="Select a producer agent"
              size="md"
              label={
                <LabelWithTooltip
                  label="Producer agent"
                  tooltipLabel="Individual within the producer agency who is the primary employer’s Point Of Contact"
                />
              }
            />
          )}
        />
        <FormErrorMessage fieldName="producerAgentId" errors={errors} />
      </Grid.Col>

      {isZorroOperations && (
        <Grid.Col span={6}>
          <Controller
            name="ccEmailList"
            control={control}
            render={({ field: { onBlur, onChange, ...rest } }) => (
              <TagsInput
                {...rest}
                onBlur={onBlur}
                onChange={(value) => {
                  onChange(value);
                  onBlur();
                }}
                rightSection={null}
                disabled={isReadonlyMode}
                styles={tagsInputStyles}
                placeholder="Type CC email list"
                label="CC email list"
                size="md"
              />
            )}
          />
          <FormErrorMessage fieldName="ccEmailList" errors={errors} />
        </Grid.Col>
      )}

      <Grid.Col span={6}>
        <Controller
          name="crmRecordId"
          control={control}
          render={({ field: { value, ...rest } }) => (
            <NumberInput
              {...rest}
              value={value || undefined}
              placeholder="Type CRM record ID"
              disabled={isReadonlyMode}
              size="md"
              label={
                <LabelWithTooltip
                  label="CRM record ID"
                  tooltipLabel="Unique identifier in the CRM system for connecting customer records across platforms"
                />
              }
            />
          )}
        />
        <FormErrorMessage fieldName="crmRecordId" errors={errors} />
      </Grid.Col>

      {shouldShowCoverageStartDate && (
        <Grid.Col span={6}>
          <Controller
            control={control}
            name="prospectCoverageStartDate"
            render={({ field: { ...rest } }) => (
              <MonthPickerInput
                {...rest}
                disabled={isReadonlyMode}
                label="Coverage start date"
                placeholder="Coverage start date"
                valueFormat={EN_LOCALE_DATE_FORMAT}
                size="md"
                required
              />
            )}
          />
        </Grid.Col>
      )}
    </Grid>
  );
};
