import { downloadEmployerDocument, useRoles } from '@zorro/shared/utils';
import {
  AddressInput,
  Checkbox,
  FormErrorMessage,
  Grid,
  LabelWithTooltip,
  Text,
  TextInput,
} from '@zorro/zorro-ui-design';
import { Control, Controller, UseFormReturn } from 'react-hook-form';

import { FileUploader } from '../FileUploader';
import { TaxIdInput } from '../TaxIdInput';
import { ReturnType, UploadLogoInput } from '../UploadLogoInput';
import { UploadedFileBox } from '../UploadedFileBox';
import { BusinessEntityInput } from './BusinessEntityInput';
import { CompanyDetailsFormFields } from './CompanyDetailsUtils';

type Props = {
  companyDetailsForm: UseFormReturn<CompanyDetailsFormFields>;
  shouldHideSignedAgreement?: boolean;
  isFinalizationMode?: boolean;
};

export const CompanyDetailsForm = ({
  companyDetailsForm,
  shouldHideSignedAgreement = false,
  isFinalizationMode = false,
}: Props) => {
  const { isAgentWithoutSupervisor: isReadonlyMode } = useRoles();
  const { watch, control, trigger, setValue, formState } = companyDetailsForm;
  const { errors } = formState;

  const signedAgreement = watch('signedAgreement');
  const isLegalNameSameAsCompanyName = watch('isLegalNameSameAsCompanyName');
  const isMailingAddressSameAsHQAddress = watch(
    'isMailingAddressSameAsHQAddress'
  );

  function deleteFile() {
    setValue('signedAgreement', undefined, { shouldValidate: true });
  }

  return (
    <Grid>
      <Grid.Col>
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <TextInput
              {...field}
              size="md"
              disabled={isReadonlyMode}
              placeholder="Type company name"
              label={
                <LabelWithTooltip
                  label="Company name (DBA)"
                  tooltipLabel="The name under which the company is doing business"
                  isRequired
                />
              }
            />
          )}
        />

        <FormErrorMessage fieldName="name" errors={errors} />
      </Grid.Col>

      <Grid.Col>
        <Controller
          control={control}
          name="isLegalNameSameAsCompanyName"
          render={({ field: { value, ...rest } }) => (
            <Checkbox
              {...rest}
              isChecked={value}
              disabled={isReadonlyMode}
              label="Legal name is the same as company name (DBA)"
              shouldHaveBorder={false}
              size="sm"
            />
          )}
        />

        <FormErrorMessage
          fieldName="isLegalNameSameAsCompanyName"
          errors={errors}
        />
      </Grid.Col>

      {!isLegalNameSameAsCompanyName && (
        <Grid.Col>
          <Controller
            control={control}
            name="legalName"
            render={({ field }) => (
              <TextInput
                {...field}
                disabled={isReadonlyMode}
                placeholder="Type legal name"
                required={isFinalizationMode}
                label="Legal name"
                size="md"
              />
            )}
          />

          <FormErrorMessage fieldName="legalName" errors={errors} />
        </Grid.Col>
      )}

      <Grid.Col>
        <Controller
          control={control}
          name="address"
          render={({ field: { onChange, ...rest } }) => (
            <AddressInput
              {...rest}
              disabled={isReadonlyMode}
              required={isFinalizationMode}
              notifySubscribers={(address: string) => onChange(address)}
              onChange={onChange}
              placeholder="Type the HQ address"
              label="HQ address"
              size="md"
            />
          )}
        />

        <FormErrorMessage fieldName="address" errors={errors} />
      </Grid.Col>

      <Grid.Col>
        <Controller
          control={control}
          name="isMailingAddressSameAsHQAddress"
          render={({ field: { value, ...rest } }) => (
            <Checkbox
              {...rest}
              isChecked={value}
              disabled={isReadonlyMode}
              label="Mailing address is the same as HQ address"
              shouldHaveBorder={false}
              size="sm"
            />
          )}
        />

        <FormErrorMessage
          fieldName="isMailingAddressSameAsHQAddress"
          errors={errors}
        />
      </Grid.Col>

      {!isMailingAddressSameAsHQAddress && (
        <Grid.Col>
          <Controller
            control={control}
            name="mailingAddress"
            render={({ field: { onChange, ...rest } }) => (
              <AddressInput
                {...rest}
                required={isFinalizationMode}
                disabled={isReadonlyMode}
                notifySubscribers={(address: string) => onChange(address)}
                onChange={onChange}
                placeholder="Type the Mailing address"
                label="Mailing address"
                size="md"
              />
            )}
          />

          <FormErrorMessage fieldName="mailingAddress" errors={errors} />
        </Grid.Col>
      )}

      {!shouldHideSignedAgreement && (
        <Grid.Col>
          {!signedAgreement && (
            <FileUploader
              control={control as unknown as Control}
              name="signedAgreement"
              label="Signed agreement"
              isLoading={false}
              onDrop={(files: File[], onBlur, onChange) => {
                onChange(files[0]);
                onBlur();
              }}
              isDisabled={isReadonlyMode}
              buttonText="Upload signed agreement"
              zoneLabelText="Or drag it here"
            />
          )}

          {signedAgreement && (
            <>
              <Text size="sm" fw={400} lts="xs">
                Signed agreement
              </Text>
              <UploadedFileBox
                name={`File: ${
                  signedAgreement instanceof File
                    ? signedAgreement.name
                    : signedAgreement.fileName
                }`}
                onClickDelete={deleteFile}
                onClickDownload={() => {
                  if (!(signedAgreement instanceof File)) {
                    downloadEmployerDocument(
                      signedAgreement.documentUrl,
                      signedAgreement.fileName
                    );
                  }
                }}
              />
            </>
          )}

          <FormErrorMessage fieldName="signedAgreement" errors={errors} />
        </Grid.Col>
      )}

      <Grid.Col span={6}>
        <Controller
          name="businessEntityType"
          control={control}
          render={({ field }) => (
            <BusinessEntityInput
              {...field}
              disabled={isReadonlyMode}
              selectProps={{ required: isFinalizationMode }}
            />
          )}
        />
        <FormErrorMessage fieldName="businessEntityType" errors={errors} />
      </Grid.Col>

      <Grid.Col span={6}>
        <TaxIdInput
          control={control}
          trigger={trigger}
          errors={errors}
          watch={watch}
          isDisabled={isReadonlyMode}
          isRequired={isFinalizationMode}
        />
      </Grid.Col>

      <Grid.Col>
        <UploadLogoInput
          isRequired={isFinalizationMode}
          isDisabled={isReadonlyMode}
          onDeleteCurrentLogo={() => {
            setValue('logo', undefined, { shouldValidate: true });
          }}
          onLogoUploaded={(logo) => {
            setValue('logo', logo, { shouldValidate: true });
          }}
          control={control}
          errors={errors}
          watch={watch}
          returnType={ReturnType.FILE}
        />
        <FormErrorMessage fieldName="logo" errors={errors} />
      </Grid.Col>
    </Grid>
  );
};
