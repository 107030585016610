type EmployeeProps = { employerId: string; employeeId: string };
type OnboardingPeriodProps = {
  employerId: string;
  employeeId: string;
  onboardingPeriodId: string;
};

type ManualSubmissionProps = OnboardingPeriodProps;
type CarrierApplicationProps = OnboardingPeriodProps;

const DOCS_URL = '/documents';

export const COMMON_ROUTES = {
  LOGIN: '/account/login',
  UNAUTHORIZED: '/unauthorized',
} as const;

export const COMMON_DYNAMIC_ROUTES = {
  LOGIN: () => '/account/login',
  UNAUTHORIZED: () => '/unauthorized',
} as const;

export const ZORRO_UI_DYNAMIC_ROUTES = {
  ...COMMON_DYNAMIC_ROUTES,
  DOCUMENTS: () => DOCS_URL,
  EMPLOYEE_KNOWLEDGE_CENTER: () => '/knowledge-center/employee',
  EMPLOYER_KNOWLEDGE_CENTER: () => '/knowledge-center/employer',
  ENROLLMENT_REPORTING: () => '/enrollment-reporting',
  ERROR: () => '/500',
  HEALTHCARE_INSURED: () => '/healthcare-insured',
  MEDICARE_PLAN_ELECTION: () => '/medicare-plan-election',
  MEDICARE_AND_REGULAR_PLAN_ELECTION: () =>
    '/medicare-and-regular-plan-election',
  HEALTHCARE_INSURED_REDIRECT: () => '/healthcare-insured-redirect',
  COVERAGE_OPTIONS: () => '/coverage-options',
  HEALTHCARE_NEEDS: () => '/healthcare-needs',
  HEALTHCARE_PREFERENCES: () => '/healthcare-preferences',
  HSA_CONTRIBUTION: () => '/hsa-contribution',
  INDEX: () => '/',
  MY_COVERAGE: () => '/my-coverage',
  NO_COVERAGE_NEEDED: () => '/no-coverage-needed',
  OPT_OUT: () => '/opt-out',
  PARTNERS_HUB: () => '/partners-hub',
  PAYMENTS: () => '/payments',
  PERSONAL_DETAILS: () => '/personal-details',
  PERSONALIZED_ALLOWANCE: () => '/personalized-allowance',
  PLANS_WITH_RECOMMENDATION: () => '/plans-with-recommendation',
  PREFERENCES: () => '/preferences',
  PRIVACY_POLICY: () => '/privacy-policy',
  QLE: () => '/qle',
  REPORT: () => '/report',
  SELF_REPORT: () => '/self-report',
  SELF_REPORT_THANK_YOU: () => '/self-report-thank-you',
  SUMMARY: () => '/summary',
  SUPPLEMENTAL_COVERAGE: () => '/supplemental-coverage',
  TERMS_AND_CONDITIONS: () => '/terms-and-conditions',
  THANK_YOU: () => '/thank-you',
  UNSUPPORTED_STATE: () => '/unsupported-state',
  MASSACHUSETTS: () => '/massachusetts',
  ...COMMON_DYNAMIC_ROUTES,
} as const;

export const MURRIETA_ROUTES = {
  ADD_EMPLOYER_COMPANY_DETAILS: '/add-employer',
  ADD_EMPLOYER_COMPANY_SETUP: '/add-employer/company-setup',
  ADD_EMPLOYER_CONTACTS: '/add-employer/contacts',
  ADD_EMPLOYER_PLAN_YEAR_SETUP: '/add-employer/plan-year-setup',
  ADD_EMPLOYER_REVIEW: '/add-employer/review',
  ADD_EMPLOYER_ZORRO_SETUP: '/add-employer/zorro-setup',
  AGENCIES: '/agencies',
  BENEFITS_SETUP: '/benefits-step',
  CENSUS: '/census',
  COMPANY: '/company',
  DEMO_EMPLOYERS: '/demo-employers',
  DEMO_PROSPECTS: '/demo-prospects',
  DOCUMENTS: DOCS_URL,
  EMPLOYEES: '/employees',
  EMPLOYERS: '/employers',
  PROSPECTS: '/prospects',
  REPORTS: '/reports',
  ROSTER: '/roster',
  UPLOAD_DOCUMENTS: '/upload-documents',
  UPLOAD_ROSTER: '/upload-roster',
  ...COMMON_ROUTES,
} as const;

export const ADD_EMPLOYER_DYNAMIC_ROUTES = {
  ADD_EMPLOYER_COMPANY_DETAILS: (employerId?: string) =>
    employerId
      ? `/add-employer/${employerId}/company-details`
      : '/add-employer',
  ADD_EMPLOYER_COMPANY_SETUP: (employerId: string) =>
    `/add-employer/${employerId}/company-setup`,
  ADD_EMPLOYER_CONTACTS: (employerId: string) =>
    `/add-employer/${employerId}/contacts`,
  ADD_EMPLOYER_PLAN_YEAR_SETUP: (employerId: string) =>
    `/add-employer/${employerId}/plan-year-setup`,
  ADD_EMPLOYER_REVIEW: (employerId: string) =>
    `/add-employer/${employerId}/review`,
  ADD_EMPLOYER_ZORRO_SETUP: (employerId: string) =>
    `/add-employer/${employerId}/zorro-setup`,
} as const;

export const COMPANY_SETUP_DYNAMIC_ROUTES = {
  COMPANY_SETUP_COMPANY_DETAILS: (employerId: string) =>
    `/company-setup/${employerId}/company-details`,
  COMPANY_SETUP_COMPANY_SETUP: (employerId: string) =>
    `/company-setup/${employerId}/company-setup`,
  COMPANY_SETUP_CONTACTS: (employerId: string) =>
    `/company-setup/${employerId}/contacts`,
  COMPANY_SETUP_PLAN_YEAR_SETUP: (employerId: string) =>
    `/company-setup/${employerId}/plan-year-setup`,
  COMPANY_SETUP_PROSPECT: (employerId: string) =>
    `/company-setup/${employerId}/prospect`,
  COMPANY_SETUP_ZORRO_SETUP: (employerId: string) =>
    `/company-setup/${employerId}/zorro-setup`,
} as const;

export const EMPLOYEE_DYNAMIC_ROUTES = {
  EMPLOYEE_GENERAL_NOTES: ({ employerId, employeeId }: EmployeeProps) =>
    `/employers/${employerId}/employees/${employeeId}/general-notes`,
  EMPLOYEE_NO_ENROLLMENTS: ({ employerId, employeeId }: EmployeeProps) =>
    `/employers/${employerId}/employees/${employeeId}/no-enrollments`,
  EMPLOYEE_ENROLLMENT_TAB: ({
    employerId,
    employeeId,
    onboardingPeriodId,
  }: OnboardingPeriodProps) =>
    `/employers/${employerId}/employees/${employeeId}/enrollments/${onboardingPeriodId}/enrollment-details`,
  EMPLOYEE_PERSONAL_DETAILS_TAB: ({
    employerId,
    employeeId,
    onboardingPeriodId,
  }: OnboardingPeriodProps) =>
    `/employers/${employerId}/employees/${employeeId}/enrollments/${onboardingPeriodId}/personal-details`,
  EMPLOYEE_FILES_TAB: ({
    employerId,
    employeeId,
    onboardingPeriodId,
  }: OnboardingPeriodProps) =>
    `/employers/${employerId}/employees/${employeeId}/enrollments/${onboardingPeriodId}/files`,
  EMPLOYEE_PAYMENT_TAB: ({
    employerId,
    employeeId,
    onboardingPeriodId,
  }: OnboardingPeriodProps) =>
    `/employers/${employerId}/employees/${employeeId}/enrollments/${onboardingPeriodId}/payments`,
  EMPLOYEE_PAGE: ({ employerId, employeeId }: EmployeeProps) =>
    `/employers/${employerId}/employees/${employeeId}`,

  // *** Manual Submission
  MANUAL_SUBMISSION_ALLOWANCE: ({
    employeeId,
    employerId,
    onboardingPeriodId,
  }: ManualSubmissionProps) =>
    `/employers/${employerId}/employees/${employeeId}/submission/${onboardingPeriodId}/allowance`,
  MANUAL_SUBMISSION_HOUSEHOLD_INFORMATION: ({
    employeeId,
    employerId,
    onboardingPeriodId,
  }: ManualSubmissionProps) =>
    `/employers/${employerId}/employees/${employeeId}/submission/${onboardingPeriodId}/household-information`,
  MANUAL_SUBMISSION_MEDICAL_PLAN: ({
    employeeId,
    employerId,
    onboardingPeriodId,
  }: ManualSubmissionProps) =>
    `/employers/${employerId}/employees/${employeeId}/submission/${onboardingPeriodId}/medical-plan`,
  // *** Manual Submission End
};

export const MURRIETA_DYNAMIC_ROUTES = {
  ...ADD_EMPLOYER_DYNAMIC_ROUTES,
  ...COMPANY_SETUP_DYNAMIC_ROUTES,
  ...EMPLOYEE_DYNAMIC_ROUTES,
  AGENCIES: () => '/agencies',
  AGENCY_SETTINGS: (agencyId: string) => `/agencies/${agencyId}`,
  COMPANY_OVERVIEW: (employerId: string) => `/companies/${employerId}/overview`,
  EMPLOYERS: () => '/employers',
  EMPLOYEES: (props: { employerId: string }) =>
    `/employers/${props.employerId}`,
  ERROR: () => '/500',

  // *** Carrier Application
  CARRIER_APPLICATION_COVERAGE_PERIOD: ({
    employeeId,
    employerId,
    onboardingPeriodId,
  }: CarrierApplicationProps) =>
    `/employers/${employerId}/employees/${employeeId}/application/${onboardingPeriodId}/coverage-period`,
  CARRIER_APPLICATION_ENROLLMENT_DETAILS: ({
    employeeId,
    employerId,
    onboardingPeriodId,
  }: CarrierApplicationProps) =>
    `/employers/${employerId}/employees/${employeeId}/application/${onboardingPeriodId}/enrollment-details`,
  CARRIER_APPLICATION_PAYMENT_METHOD: ({
    employeeId,
    employerId,
    onboardingPeriodId,
  }: CarrierApplicationProps) =>
    `/employers/${employerId}/employees/${employeeId}/application/${onboardingPeriodId}/payment-method`,
  CARRIER_APPLICATION_PAYMENT_STATUS: ({
    employeeId,
    employerId,
    onboardingPeriodId,
  }: CarrierApplicationProps) =>
    `/employers/${employerId}/employees/${employeeId}/application/${onboardingPeriodId}/payment-status`,
  CARRIER_APPLICATION_PREMIUM_AMOUNT: ({
    employeeId,
    employerId,
    onboardingPeriodId,
  }: CarrierApplicationProps) =>
    `/employers/${employerId}/employees/${employeeId}/application/${onboardingPeriodId}/premium-amount`,
  CARRIER_APPLICATION_SUBMISSION: ({
    employeeId,
    employerId,
    onboardingPeriodId,
  }: CarrierApplicationProps) =>
    `/employers/${employerId}/employees/${employeeId}/application/${onboardingPeriodId}/submission`,
  // *** Carrier Application End

  // *** Enrollment Confirmation
  ENROLLMENT_CONFIRMATION_ENROLLMENT_DETAILS: ({
    employeeId,
    employerId,
    onboardingPeriodId,
  }: ManualSubmissionProps) =>
    `/employers/${employerId}/employees/${employeeId}/confirmation/${onboardingPeriodId}/enrollment-details`,
  ENROLLMENT_CONFIRMATION_PAYMENT_METHOD: ({
    employeeId,
    employerId,
    onboardingPeriodId,
  }: ManualSubmissionProps) =>
    `/employers/${employerId}/employees/${employeeId}/confirmation/${onboardingPeriodId}/payment-method`,
  // *** Enrollment Confirmation End
} as const;

export const DON_DYNAMIC_ROUTES = {
  ...ADD_EMPLOYER_DYNAMIC_ROUTES,
  ...COMPANY_SETUP_DYNAMIC_ROUTES,
  ADD_EMPLOYER_GET_STARTED: (
    employerId: string,
    employerName: string,
    activationLink: string
  ) =>
    `/add-employer/${employerId}/${employerName}/${activationLink}/get-started`,
  ADD_EMPLOYER_THANK_YOU: (employerId: string) =>
    `/add-employer/${employerId}/thank-you`,
  COMPANY: () => '/admin/company',
  EMPLOYEES: () => '/admin/employees',
};

export const DON_ROUTES = {
  COMPANY: '/admin/company',
  DOCUMENTS: '/admin/documents',
  EMPLOYEES: '/admin/employees',
  REPORTS: '/admin/reports',
  ROSTER: '/admin/roster',
  ...COMMON_ROUTES,
} as const;

export const TORNADO_ROUTES = {
  ALLOWANCE: '/allowance',
  BUDGET_PLANNING: '/budget-planning',
  EMPLOYEE_BREAKDOWN: '/employee-breakdown',
  INDEX: '/',
  ONLINE_QUOTE: '/online-quote',
  PLAN_COMPARISON: '/plan-comparison',
  PLAN_DIVERSITY: '/plan-diversity',
  ...COMMON_ROUTES,
} as const;
