import { EmployerSignupStatus } from '@zorro/clients';
import {
  MURRIETA_DYNAMIC_ROUTES,
  showSuccessNotification,
  useDynamicMurrietaRouter,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import { useState } from 'react';
import { $enum } from 'ts-enum-util';

import { useCompanyDetailsForm } from '../../CompanyDetailsForm';
import { useCompanySetupForm } from '../../CompanySetupForm';
import { useContactsForm } from '../../ContactsForm';
import { usePlanYearSetupForm } from '../../PlanYearSetupForm';
import { useZorroSetupForm } from '../../ZorroSetupForm';
import { useMonolithMutation } from '../../hooks';
import {
  AddEmployerStep,
  AddEmployerStepType,
  HandleStepSubmit,
} from '../AddEmployerStep';
import { CancelInviteModal } from './CancelInviteModal';
import { CompanyDetailsCard } from './Cards/CompanyDetailsCard';
import { CompanySetupCard } from './Cards/CompanySetupCard';
import { ContactsCard } from './Cards/ContactsCard';
import { PlanYearSetupCard } from './Cards/PlanYearSetupCard';
import { ZorroSetupCard } from './Cards/ZorroSetupCard';
import { ReviewStatus } from './ReviewStatus';
import { SendInviteModal } from './SendInviteModal/SendInviteModal';
import { useReviewFormFinalization } from './useReviewFormFinalization';

export const getReviewFormStatusesConfig = (
  signupStatus?: EmployerSignupStatus
):
  | {
      nextSignupStatus: EmployerSignupStatus;
      successMessage: string;
      submitLabel: string;
      navigateTo?: keyof typeof MURRIETA_DYNAMIC_ROUTES;
      shouldActivateEmployerAdmin?: boolean;
      isZorroOnly?: boolean;
    }
  | undefined => {
  return $enum.mapValue(signupStatus).with({
    [EmployerSignupStatus.NEEDS_REVIEW]: {
      nextSignupStatus: EmployerSignupStatus.IN_ZORRO_SETUP,
      successMessage: 'Employer successfully finalized',
      submitLabel: 'Finalize',
    },
    [EmployerSignupStatus.IN_ZORRO_SETUP]: {
      nextSignupStatus: EmployerSignupStatus.ACTIVE,
      successMessage: 'Employer activated successfully',
      shouldActivateEmployerAdmin: true,
      submitLabel: 'Activate account',
      navigateTo: 'COMPANY_OVERVIEW',
      isZorroOnly: true,
    },
    [EmployerSignupStatus.WAITING_FOR_EMPLOYER]: undefined,
    [EmployerSignupStatus.PROSPECT]: undefined,
    [EmployerSignupStatus.ACTIVE]: undefined,
    [EmployerSignupStatus.DRAFT]: undefined,
    [$enum.handleUnexpected]: undefined,
    [$enum.handleUndefined]: undefined,
  });
};

type Props = {
  employerId: string;
  isFinalizationMode?: boolean;
};

export const ReviewForm = ({
  employerId,
  isFinalizationMode = false,
}: Props) => {
  const { isZorroOperations } = useRoles();
  const { navigateToRoute } = useDynamicMurrietaRouter();
  const [isSendInviteModalOpen, setIsSendInviteModalOpen] =
    useState<boolean>(false);
  const [isCancelInviteModalOpen, setIsCancelInviteModalOpen] =
    useState<boolean>(false);

  const { mutate: mutateEmployer } = useMonolithMutation({
    method: 'employersControllerUpdate',
  });
  const { mutate: mutateActivateContacts } = useMonolithMutation({
    method: 'employersControllerActivateContacts',
    shouldInvalidateQueries: false,
  });
  const { data: employer } = useMonolithQuery({
    method: 'employersControllerFindOne',
    params: employerId && [employerId],
  });

  const { companyDetailsForm } = useCompanyDetailsForm({
    employerId,
    isFinalizationMode,
  });
  const { contactsForm } = useContactsForm(employerId, isFinalizationMode);
  const { companySetupForm } = useCompanySetupForm(
    employerId,
    isFinalizationMode
  );
  const { zorroSetupForm, allAgencies } = useZorroSetupForm({ employerId });
  const { planYearSetupForm } = usePlanYearSetupForm({
    shouldFallbackToEarliestPeriod: true,
    isFinalizationMode,
    employerId,
  });

  const { isFinalizationModeValid, shouldHideActivationButtons } =
    useReviewFormFinalization({
      employerStatus: employer?.employerStatus,
      isFinalizationMode,
      employerId,
    });

  const {
    submitLabel,
    successMessage,
    nextSignupStatus,
    navigateTo,
    isZorroOnly,
    shouldActivateEmployerAdmin,
  } = getReviewFormStatusesConfig(employer?.signupStatus) || {};

  const isSubmitDisabled =
    !companyDetailsForm.formState.isValid ||
    !contactsForm.formState.isValid ||
    !companySetupForm.formState.isValid ||
    !zorroSetupForm.formState.isValid ||
    !planYearSetupForm.formState.isValid ||
    (isZorroOnly && !isZorroOperations);

  const handleSubmit: HandleStepSubmit = async () => {
    if (nextSignupStatus && successMessage) {
      try {
        if (shouldActivateEmployerAdmin) {
          await mutateActivateContacts([employerId]);
        }
        await mutateEmployer([
          employerId,
          {
            zorroPartnerId: employer?.zorroPartnerId || null,
            isProspect: false,
            signupStatus: nextSignupStatus,
          },
        ]);

        showSuccessNotification({ message: successMessage });
        if (navigateTo) {
          navigateToRoute(navigateTo, [employerId]);
        }
      } catch {
        /* empty */
      }
    } else {
      setIsSendInviteModalOpen(true);
    }

    return { navigate: false };
  };

  const handleSkipInvitation = async () => {
    try {
      const skipConfig = getReviewFormStatusesConfig(
        EmployerSignupStatus.NEEDS_REVIEW
      );

      if (!skipConfig) return;

      await mutateEmployer([
        employerId,
        {
          zorroPartnerId: employer?.zorroPartnerId || null,
          isProspect: false,
          signupStatus: skipConfig.nextSignupStatus,
        },
      ]);

      showSuccessNotification({ message: skipConfig.successMessage });
    } catch {
      /* empty */
    }
  };

  const employerName = employer?.name || '';

  return (
    <AddEmployerStep
      isSubmitDisabled={isSubmitDisabled}
      isFinalizationMode={isFinalizationMode}
      step={AddEmployerStepType.REVIEW}
      title="Review employer info"
      submitLabel={submitLabel || 'Invite employer to complete'}
      employerId={employerId}
      onSubmit={handleSubmit}
      secondaryLabel={
        isFinalizationModeValid ? 'Skip invitation and finalize' : undefined
      }
      secondaryButtonProps={{ onClick: handleSkipInvitation }}
      paperProps={shouldHideActivationButtons ? { display: 'none' } : undefined}
      isDon={false}
    >
      {employer && <ReviewStatus employer={employer} />}
      <CompanyDetailsCard
        isFinalizationMode={isFinalizationMode}
        isValid={companyDetailsForm.formState.isValid}
        fields={companyDetailsForm.watch()}
        employerId={employerId}
      />
      <ContactsCard
        isFinalizationMode={isFinalizationMode}
        isValid={contactsForm.formState.isValid}
        fields={contactsForm.watch()}
        employerId={employerId}
      />
      <CompanySetupCard
        isFinalizationMode={isFinalizationMode}
        isValid={companySetupForm.formState.isValid}
        fields={companySetupForm.watch()}
        employerId={employerId}
      />
      <ZorroSetupCard
        isValid={zorroSetupForm.formState.isValid}
        zorroSetupForm={zorroSetupForm}
        allAgencies={allAgencies}
        employerId={employerId}
      />
      <PlanYearSetupCard
        isFinalizationMode={isFinalizationMode}
        isValid={planYearSetupForm.formState.isValid}
        fields={planYearSetupForm.watch()}
        employerName={employerName}
        employerId={employerId}
      />
      <CancelInviteModal
        setIsOpened={setIsCancelInviteModalOpen}
        isOpened={isCancelInviteModalOpen}
        employerName={employerName}
      />
      <SendInviteModal
        setIsOpen={setIsSendInviteModalOpen}
        isOpen={isSendInviteModalOpen}
        employerName={employerName}
        employerId={employerId}
      />
    </AddEmployerStep>
  );
};
