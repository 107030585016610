import { SubmissionType } from '@zorro/clients';
import { EditColorIcon } from '@zorro/shared/assets';
import {
  Button,
  Center,
  Flex,
  Modal,
  Select,
  Stack,
  Text,
} from '@zorro/zorro-ui-design';
import { useState } from 'react';

import { EmployeeRow } from '../../EmployeesDatatable';
import { useBatchCallEndpoint } from '../../hooks';
import { ErrorsTable } from '../Errors/ErrorsTable';

function calculateInitialActiveStatus(selectedEmployees: EmployeeRow[]) {
  if (selectedEmployees.length > 1) {
    const onBoarding = { active: 0, inactive: 0 };
    selectedEmployees.forEach((employee) => {
      if (employee.onboardingStatus === 'Active') {
        onBoarding.active = onBoarding.active + 1;
      } else {
        onBoarding.inactive = onBoarding.inactive + 1;
      }
    });
    return onBoarding.active > onBoarding.inactive;
  }
  return selectedEmployees[0].onboardingStatus === 'Active';
}

export type ChangeShoppingStatusModalProps = {
  selectedEmployees: EmployeeRow[];
  onClose: () => void;
  onSuccess: () => void;
};

export const ChangeShoppingStatusModal = ({
  selectedEmployees,
  onClose,
  onSuccess,
}: ChangeShoppingStatusModalProps) => {
  const [isActive, setIsActive] = useState<boolean>(
    calculateInitialActiveStatus(selectedEmployees)
  );
  const { executeBatchCall, errors } = useBatchCallEndpoint({
    methodName: 'onboardingPeriodsControllerUpdateByAdmin',
    singularItemName: 'onboarding period',
    action: 'updated',
    batchSize: 5,
  });

  async function changeOnboardingStatus() {
    const batchItems = selectedEmployees.map(
      ({ onboardingPeriodId, fullName }) => ({
        key: fullName,
        params: [
          onboardingPeriodId as string, // modal is only available for employees with an onboarding period
          { isActive, submissionType: SubmissionType.BY_OPERATOR },
        ] as const,
      })
    );

    await executeBatchCall(batchItems, { onSuccess });
  }

  async function handleSubmit() {
    await changeOnboardingStatus();
  }

  return (
    <Modal
      size="md"
      onClose={onClose}
      styles={{
        body: {
          paddingRight: 24,
          paddingLeft: 24,
        },
      }}
      title={
        <Flex align="center">
          <>
            <EditColorIcon />
            <Text size="xl" ml="sm" fw={500}>
              Change Shopping Status
            </Text>
          </>
        </Flex>
      }
    >
      <Stack>
        <Stack>
          <Text>Set shopping status as:</Text>
          <Select
            defaultValue={isActive ? 'Active' : 'Inactive'}
            data={[
              { value: 'Active', label: 'Active' },
              { value: 'Inactive', label: 'Inactive' },
            ]}
            onChange={(value) => {
              setIsActive(value === 'Active');
            }}
          />
        </Stack>

        <Center>
          <Button onClick={handleSubmit} size="lg">
            Save
          </Button>
        </Center>

        <ErrorsTable
          tableTitle="Employee name"
          errors={errors}
          isBulk={selectedEmployees?.length > 1}
        />
      </Stack>
    </Modal>
  );
};
