import { yupResolver } from '@hookform/resolvers/yup';
import { useQueries } from '@tanstack/react-query';
import {
  OnboardingType,
  OpenEnrollmentPeriodDto,
  SubmissionType,
} from '@zorro/clients';
import {
  formatDateISO,
  parseDate,
  parseDateISO,
} from '@zorro/shared/formatters';
import {
  UNEXPECTED_ERROR_MESSAGE,
  callEndpoint,
  maximalDateISO,
  minimalDateISO,
  showErrorNotification,
  useForm,
  useMonolithQuery,
} from '@zorro/shared/utils';
import {
  Box,
  Button,
  Center,
  DateInput,
  DateRangeInput,
  Group,
} from '@zorro/zorro-ui-design';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import { InformationBoxComponent } from '../../InformationBox/InformationBoxComponent';
import { useBatchCallEndpoint } from '../../hooks';
import { ErrorsTable } from '../Errors/ErrorsTable';
import {
  ChangeElectionWindowFormFields,
  ChangeElectionWindowSchemaObject,
  ChangeEnrollmentsFormFields,
  addOrEditAlertBoxText,
  formFieldStyle,
  getSharedEnrollmentType,
  handleFormStateChange,
  inlineGroupStyle,
} from './changeEnrollment.utils';

interface ChangeElectionWindowFormProps {
  planYearId: OpenEnrollmentPeriodDto['id'];
  selectedEmployees: {
    id: string;
    firstName: string;
    lastName: string;
    onboardingPeriodId?: string;
  }[];
  onSuccess?: () => void;
}

export const ChangeElectionWindowForm = ({
  selectedEmployees,
  planYearId,
  onSuccess,
}: ChangeElectionWindowFormProps) => {
  const isSingleEmployee = selectedEmployees.length === 1;
  const singleEmployee = selectedEmployees[0];

  const { data: openEnrollmentDto } = useMonolithQuery({
    method: 'openEnrollmentPeriodsControllerFind',
    params: [planYearId],
  });

  const { data: associatedOnboardingPeriodsSingleEmployee } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindMany',
    params: [singleEmployee?.id],
    enabled: isSingleEmployee,
  });

  const associatedOtherOnboardingPeriodsSingleEmployee =
    associatedOnboardingPeriodsSingleEmployee?.filter(
      (onboardingPeriod) =>
        onboardingPeriod.id !== singleEmployee.onboardingPeriodId
    );

  const allEditedOnboardingPeriodsMultipleEmployees = useQueries({
    queries: selectedEmployees.map(({ id, onboardingPeriodId = '' }) => {
      return {
        queryKey: ['onboardingPeriodsControllerFindMany', [id]],
        queryFn: async () =>
          callEndpoint({
            method: 'onboardingPeriodsControllerFindOne',
            params: [id, onboardingPeriodId],
          }),
      };
    }),
  }).flatMap((query) => query.data ?? []);

  const singleEmployeeOnboardingPeriodForDefaultValues = isSingleEmployee
    ? allEditedOnboardingPeriodsMultipleEmployees[0]
    : undefined;

  const { data: singleEmployeeDto } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [singleEmployee?.id],
    enabled: isSingleEmployee,
  });

  const {
    executeBatchCall: updateOnboardingPeriods,
    errors: updateOnboardingPeriodsErrors,
  } = useBatchCallEndpoint({
    methodName: 'onboardingPeriodsControllerUpdateByAdmin',
    singularItemName: 'Election window',
    action: 'updated',
  });

  const sharedEnrollmentType = getSharedEnrollmentType(
    allEditedOnboardingPeriodsMultipleEmployees
  );

  const editModeSingleEmployeeDefaultValues:
    | ChangeElectionWindowFormFields
    | undefined =
    isSingleEmployee && singleEmployeeOnboardingPeriodForDefaultValues
      ? {
          electionWindow: [
            parseDateISO(
              singleEmployeeOnboardingPeriodForDefaultValues.onboardingFrom
            ).toDate(),
            parseDateISO(
              singleEmployeeOnboardingPeriodForDefaultValues.onboardingUntil
            ).toDate(),
          ],
          expectedStartOfCoverage: parseDateISO(
            singleEmployeeOnboardingPeriodForDefaultValues.targetEnrollmentDate
          ).toDate(),
        }
      : undefined;

  const { control, setValue, handleSubmit, formState } =
    useForm<ChangeElectionWindowFormFields>({
      mode: 'all',
      resolver: yupResolver(
        yup.object().shape(ChangeElectionWindowSchemaObject)
      ),
      defaultValues: isSingleEmployee
        ? editModeSingleEmployeeDefaultValues
        : undefined, // no default values for bulk edit mode
    });

  const { isValid } = formState;

  const electionWindow = useWatch({
    control,
    name: 'electionWindow',
  });

  const formValues = {
    planYear: openEnrollmentDto,
    enrollmentType: sharedEnrollmentType,
    electionWindow,
  };

  const onSubmit = handleSubmit(async (data) => {
    const { electionWindow, expectedStartOfCoverage } = data;
    const [electionFrom, electionUntil] = electionWindow || [];

    if (!electionFrom || !electionUntil || !expectedStartOfCoverage) {
      showErrorNotification({ message: UNEXPECTED_ERROR_MESSAGE });
      return;
    }

    const electionWindowOnlyData = {
      onboardingFrom: formatDateISO(electionFrom),
      onboardingUntil: formatDateISO(electionUntil),
      expectedStartOfCoverage: formatDateISO(expectedStartOfCoverage),
    };

    await updateOnboardingPeriods(
      selectedEmployees.map(
        ({ firstName, lastName, onboardingPeriodId = '' }) => {
          return {
            key: `${firstName} ${lastName}`,
            params: [
              onboardingPeriodId,
              {
                ...electionWindowOnlyData,
                submissionType: SubmissionType.BY_OPERATOR,
              },
            ],
          };
        }
      ),
      {
        onSuccess,
      }
    );
  });

  return (
    <form onSubmit={onSubmit}>
      <Group>
        <InformationBoxComponent
          title={addOrEditAlertBoxText(isSingleEmployee)}
        />
        <Box style={{ width: '100%' }}>
          <Box style={inlineGroupStyle}>
            <Controller
              control={control}
              name="electionWindow"
              render={({ field: { onChange, ...rest } }) => (
                <DateRangeInput
                  {...rest}
                  required
                  label="Election window"
                  maxDate={
                    sharedEnrollmentType === OnboardingType.OPEN_ENROLLMENT &&
                    openEnrollmentDto?.effectiveFrom
                      ? parseDateISO(openEnrollmentDto.effectiveFrom)
                          .subtract(1, 'day')
                          .toDate()
                      : undefined
                  }
                  onChange={(value) => {
                    const [newFrom, newUntil] = value;
                    if (newFrom && newUntil) {
                      formValues.electionWindow = [newFrom, newUntil];
                      handleFormStateChange(
                        formValues,
                        'electionWindow',
                        setValue as unknown as UseFormReturn<ChangeEnrollmentsFormFields>['setValue'],
                        singleEmployeeDto,
                        associatedOtherOnboardingPeriodsSingleEmployee
                      );
                    }
                    onChange(value);
                  }}
                  style={formFieldStyle}
                  disabled={!openEnrollmentDto}
                />
              )}
            />
            <Controller
              control={control}
              name="expectedStartOfCoverage"
              render={({ field }) => (
                <DateInput
                  {...field}
                  required
                  label="Expected start of coverage"
                  style={formFieldStyle}
                  minDate={
                    isSingleEmployee
                      ? sharedEnrollmentType === OnboardingType.SPECIAL &&
                        openEnrollmentDto?.effectiveFrom &&
                        electionWindow?.length
                        ? parseDateISO(
                            maximalDateISO(
                              formatDateISO(
                                parseDate(electionWindow?.[1]).add(1, 'day')
                              ),
                              openEnrollmentDto?.effectiveFrom,
                              singleEmployeeDto?.eligibleFrom
                            )
                          ).toDate()
                        : undefined
                      : electionWindow?.[1]
                      ? parseDateISO(
                          maximalDateISO(
                            formatDateISO(parseDate(electionWindow[1])),
                            openEnrollmentDto?.effectiveFrom
                          )
                        ).toDate()
                      : undefined
                  }
                  maxDate={
                    openEnrollmentDto?.effectiveUntil
                      ? sharedEnrollmentType === OnboardingType.SPECIAL
                        ? parseDateISO(
                            minimalDateISO(
                              openEnrollmentDto.effectiveUntil,
                              singleEmployeeDto?.eligibleUntil
                            )
                          ).toDate()
                        : parseDateISO(
                            openEnrollmentDto.effectiveUntil
                          ).toDate()
                      : undefined
                  }
                  disabled={!openEnrollmentDto}
                  readOnly={
                    sharedEnrollmentType === OnboardingType.OPEN_ENROLLMENT
                  }
                />
              )}
            />
          </Box>
        </Box>
      </Group>
      <Center>
        <Button type="submit" size="lg" ml="xl" disabled={!isValid}>
          Submit
        </Button>
      </Center>

      <ErrorsTable
        tableTitle="Employee name"
        errors={updateOnboardingPeriodsErrors}
        isBulk={selectedEmployees?.length > 1}
      />
    </form>
  );
};
