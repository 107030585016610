import { IconArrowsSort } from '@tabler/icons-react';
import { ValueOf } from '@zorro/types';

import { ZorroActionIcon, ZorroIcon } from '../../Icon';
import { brand } from '../../theme';
import classNames from './NewTableSortIcon.module.scss';

type Props = {
  direction?: 'asc' | 'desc';
  color?: ValueOf<typeof brand>;
};

export function ZorroNewTableSortIcon({
  direction,
  color = brand.zorroCoal900,
}: Props) {
  return (
    <ZorroActionIcon
      variant="transparent"
      data-direction={direction}
      classNames={classNames}
    >
      <ZorroIcon icon={IconArrowsSort} color={color} />
    </ZorroActionIcon>
  );
}
